/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
// react plugin for creating charts
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import DateRange from "@material-ui/icons/DateRange";
import { CardMedia } from "@material-ui/core";

// core components
import Card from "components/Card/Card.js";
// import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import axios from "axios";
import { format } from "timeago.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function Token(props) {
  const classes = useStyles();
  const [token, setToken] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(props.tokenURI);
      if (res) {
        console.log(res.data);
        setToken(res.data);
      }
    };
    fetchData();
  }, []);
  return (
    <Card>
      {/* <CardToken color="grey"> */}
      {token && (
        <div>
          <div className="container">
            <CardMedia
              style={{
                height: 200,
                maxHeight: 200,
                "background-size": "contain"
              }}
              image={token.image}
            />
            <div className="overlay2">
              <div className="text">{token.name}</div>
            </div>
          </div>
          {/* </CardToken> */}
          {/* <CardHeader
            style={{
              "padding-bottom": "0",
              height: "10px",
              "text-overflow": "ellipsis"
            }}
          >
            {token.name}
          </CardHeader> */}
          <CardFooter stats>
            {/* <div className={classes.stats}> */}
              {/* {token.description} */}

              {/* <DateRange />
              {format("2021-12-20T12:00:00.113Z")} */}
            {/* </div> */}
          </CardFooter>
        </div>
      )}
    </Card>
  );
}
