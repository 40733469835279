
import React, { useEffect, useState } from "react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// core components

import TokenMorpher from "views/Claim/Components/TokenMorpher"
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { getUserSession } from "services/UserManagement";
import { getTokenById, getTokenBalanceFromStellar } from "services/TokenManagement";
import {getTransferByNidAndReceiver } from "services/TransferManagement";
// import Paginations from "components/Pagination/Pagination.js";
import Pagination from '@material-ui/lab/Pagination';
import Grid from '@material-ui/core/Grid';
import DummyToken from "views/Claim/Components/DummyToken"
import {NiftronDistributorPublicKey } from "variables/constants";

const useStyles = makeStyles(styles);

export default function Claim(props) {
  const classes = useStyles();
  const { id } = props.match.params

  const [user, setUser] = useState(getUserSession())
  const [token, setToken] = useState(null)
  const [transfer, setTransfer] = useState(null)
  const [loading, setloading] = useState(false)
  const [loadingText, setLoadingText] = useState("loading")
  const [placeholder, setPlaceholder] = useState([0])
  useEffect(() => {
    const fetchData = async () => {
      setloading(true)
      setLoadingText("Verifying Token In Stellar")
      const balances = await getTokenBalanceFromStellar(NiftronDistributorPublicKey)
      setLoadingText("Retrieving MetaData From Niftron")

      let result = await getTokenById(id)
      if (result != null) {
        if (result.data) {

          console.log(result.data)
          if (balances != null) {
            console.log(balances)

            balances.forEach((sToken) => {
              if (sToken.assetCode == result.data.assetCode) {
                result.data.assetCount = sToken.balance * 10000000
              }
            })
          }

          setToken(result.data)
        }
        setloading(false)
      }
    }

    fetchData()
  }, []);

  return (
    <div>
      {loading &&
        <DummyToken loadingText={loadingText} />
      }
      {!loading && token &&
        <TokenMorpher item={token} publicKey={user.publicKey} alias={user.alias} history={props.history} />
      }
    </div>
  );
}
