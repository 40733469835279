import axios from "axios";
import { niftronTokenLambda } from "variables/constants";
import { NiftronAPI, NiftronDistributorPublicKey } from "variables/constants";
// import { Server } from "stellar-sdk";
// import store from "redux/reducers";
// let StellarUrl = store.getState().StellarUrl;
// let StellarUrlTest = store.getState().StellarUrlTest;

// store.subscribe(() => {
//   StellarUrl = store.getState().StellarUrl
//   StellarUrlTest = store.getState().StellarUrlTest
// })

export async function AddClaim(accessModel) {
  try {
    let token;
    if (localStorage.getItem("token") != null) {
      token = localStorage.getItem("token");
    }
    let postBody = accessModel;
    const res = await axios.post(niftronTokenLambda + "/claims", postBody, {
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json"
      }
    });

    if (res === null) {
      return null;
    }
    return res.status;
  } catch (err) {
    return null;
  }
}

export async function claimCoupon(claimerPublicKey, code) {
  try {
    let token;
    if (localStorage.getItem("token") != null) {
      token = localStorage.getItem("token");
    }
    let postBody = {
      claimerPublicKey,
      merchantPublicKey: NiftronDistributorPublicKey,
      code
    };
    const res = await axios.post(NiftronAPI + "/rewards/claimNFT", postBody, {
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json"
      }
    });
    if (res === null) {
      throw new Error("Promo Code claiming failed");
    }
    switch (res.status) {
      case 200:
        return res.status;
      case 201:
        throw new Error("Invalid merchant");
      case 202:
        throw new Error("Promo Code code not found");
      case 203:
        throw new Error("Account not found in niftron");
      case 204:
        throw new Error("Only developer accounts can redeem credits");
      case 205:
        throw new Error("Promo Code already claimed");
      case 206:
        throw new Error("Promo Code limit reached");
      case 207:
        throw new Error("Promo Code expired");
      case 400:
        throw new Error("Promo Code claiming failed");
      case null:
        throw new Error("Promo Code claiming failed");
      default:
        throw new Error("Promo Code claiming failed");
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
}
