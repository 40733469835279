/* eslint-disable react/prop-types */
import React from "react";
// react plugin for creating charts
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import DateRange from "@material-ui/icons/DateRange";
import { CardMedia } from "@material-ui/core";

// core components
import Card from "components/Card/Card.js";
import CardFooter from "components/Card/CardFooter.js";

import { format } from "timeago.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function Certificate(props) {
  const classes = useStyles();
  return (
    <Card>
      {/* <CardToken color="grey"> */}
      <div className="container">
        <CardMedia
          style={{ height: 200, maxHeight: 200, "background-size": "contain" }}
          image={props.item.previewUrl}
        />
        <div className="overlay2">
          <div className="text">{props.item.tokenName}</div>
        </div>
      </div>
      {/* </CardToken> */}
      <CardFooter stats>
        <div className={classes.stats}>
          <DateRange />
          {format(props.item.createdAt)}
        </div>
      </CardFooter>
    </Card>
  );
}
