import nacl from "tweetnacl-zeemzo";
import naclUtil from "tweetnacl-util";
import { convertPublicKey, convertSecretKey } from "ed2curve";
import { StrKey } from "stellar-base";
import { Keypair } from "stellar-sdk";

export async function NaclKeyPairFromStellarSecret(secretKeyInput) {
  const secretKey = StrKey.decodeEd25519SecretSeed(secretKeyInput);
  const keyPair = nacl.sign.keyPair.fromSecretKey(secretKey);
  return {
    secretKey: naclUtil.encodeBase64(keyPair.secretKey),
    publicKey: naclUtil.encodeBase64(keyPair.publicKey)
  };
}

export async function NaclSecretKeyFromStellarSecret(secretKeyInput) {
  const secretKey = StrKey.decodeEd25519SecretSeed(secretKeyInput);
  return naclUtil.encodeBase64(secretKey);
}

export async function NaclPublicKeyFromStellarPublic(publicKeyInput) {
  const publicKey = StrKey.decodeEd25519PublicKey(publicKeyInput);
  return naclUtil.encodeBase64(publicKey);
}

export async function StellarKeyPairFromNaclSecret(secretKeyInput) {
  const some = Buffer.from(naclUtil.decodeBase64(secretKeyInput));
  return Keypair.fromSecret(StrKey.encodeEd25519SecretSeed(some));
}

export async function StellarSecretKeyFromNaclSecret(secretKeyInput) {
  const some = Buffer.from(naclUtil.decodeBase64(secretKeyInput));
  return StrKey.encodeEd25519SecretSeed(some);
}

export async function StellarPublicKeyFromNaclPublic(publicKeyInput) {
  const some = Buffer.from(naclUtil.decodeBase64(publicKeyInput));
  return StrKey.encodeEd25519PublicKey(some);
}

export async function encrypt(
  dataValue,
  theirPublicKeyValue,
  mySecretKeyValue
) {
  try {
    let data = naclUtil.decodeUTF8(dataValue);
    const theirPublicKey = convertPublicKey(
      naclUtil.decodeBase64(theirPublicKeyValue)
    );
    const mySecretKey = convertSecretKey(
      naclUtil.decodeBase64(mySecretKeyValue)
    );
    const nonce = nacl.randomBytes(nacl.box.nonceLength);
    if (theirPublicKey == null) {
      return {
        data: naclUtil.encodeBase64(data),
        nonce: naclUtil.encodeBase64(nonce)
      };
    }
    data = nacl.box(data, nonce, theirPublicKey, mySecretKey);
    return {
      data: naclUtil.encodeBase64(data),
      nonce: naclUtil.encodeBase64(nonce)
    };
  } catch (err) {
    console.log(err);
    throw new Error(err);
  }
}

export async function decrypt(
  dataValue,
  nonceValue,
  theirPublicKeyValue,
  mySecretKeyValue
) {
  try {
    let data = naclUtil.decodeBase64(dataValue);
    const nonce = naclUtil.decodeBase64(nonceValue);
    const theirPublicKey = convertPublicKey(
      naclUtil.decodeBase64(theirPublicKeyValue)
    );
    const mySecretKey = convertSecretKey(
      naclUtil.decodeBase64(mySecretKeyValue)
    );
    if (theirPublicKey == null) {
      return null;
    }
    const decryptedData = nacl.box.open(
      data,
      nonce,
      theirPublicKey,
      mySecretKey
    );
    if (decryptedData == null) {
      throw new Error("failed opening nacl.box");
    }
    return naclUtil.encodeUTF8(decryptedData);
  } catch (err) {
    console.log(err);
    throw new Error(err);
  }
}
