/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// core components
import TokenMorpherMatic from "views/Token/Components/TokenMorpherMatic";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { getUserSession } from "services/UserManagement";
// import Paginations from "components/Pagination/Pagination.js";
import DummyToken from "views/Token/Components/DummyToken";
import { web3Providers } from "../../variables/constants";
import Web3 from "web3";
import axios from "axios";

var maticWeb3 = new Web3(web3Providers.providerMatic);
const maticContractList = ["0x18bb126dcc43b87e091c16f354d54e350400bf42"];
const ERC721ABI = require("./ERC721.json");

const useStyles = makeStyles(styles);

export default function Token(props) {
  const classes = useStyles();
  const { id } = props.match.params;

  const [user] = useState(getUserSession());
  const [token, setToken] = useState(null);
  const [loading, setloading] = useState(false);
  const [loadingText, setLoadingText] = useState("loading");
  useEffect(() => {
    const fetchData = async () => {
      setloading(true);
      setLoadingText("Verifying Token In Matic");
      // setLoadingText("Retrieving MetaData From Niftron");
      //only for ICTA MATIC

      if (user.accounts && user.accounts.length >= 3) {
        const ethereumKey = user.accounts[2].publicKey;
        const maticContract = new maticWeb3.eth.Contract(
          ERC721ABI,
          maticContractList[0]
        );
        const tokenURI = await maticContract.methods
          .tokenURI(id)
          .call({ from: ethereumKey });

        setLoadingText("Retrieving MetaData From IPFS");

        const res = await axios.get(tokenURI);
        if (res) {
          console.log(res.data);
          setToken(res.data);
        }
      }

      setloading(false);
    };

    fetchData();
  }, []);

  return (
    <div>
      {loading && <DummyToken loadingText={loadingText} />}
      {!loading && token && (
        <TokenMorpherMatic
          isMatic={true}
          item={token}
          tokenID={id}
          contractID={maticContractList[0]}
          publicKey={user.publicKey}
          alias={user.alias}
        />
      )}
    </div>
  );
}
