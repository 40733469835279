import React, { useState } from 'react'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import InputAdornment from '@material-ui/core/InputAdornment'
import Icon from '@material-ui/core/Icon'
import sha256 from 'sha256'
import Grid from '@material-ui/core/Grid'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardFooter from 'components/Card/CardFooter.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dropzone from 'react-dropzone'
import { Link } from 'react-router-dom'
import { getUserSession, decryptSecret } from 'services/UserManagement'
import LinearProgress from '@material-ui/core/LinearProgress'
import { useSnackbar } from 'notistack'
import { crossExchangeExpereiment } from 'services/TransferManagement'
import styles from 'assets/jss/material-kit-react/views/loginPage.js'
import { Keypair } from 'stellar-sdk'
import PublicIcon from '@material-ui/icons/Public'
import CustomInputSelect from 'components/CustomInputSelect/CustomInputSelect'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Web3 from 'web3'
import BN from 'bn.js'
import { web3Providers, contracts, projectIssuers } from 'variables/constants'
import axios from 'axios'

const ERC721ABI = require('./ERC721.json')

const useStyles = makeStyles(styles)

function CrossExhangeTokenComponent(props) {
  const [cardAnimaton, setCardAnimation] = React.useState('cardHidden')
  setTimeout(function () {
    setCardAnimation('')
  }, 400)
  const classes = useStyles()
  const [user, setUser] = useState(getUserSession())
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = React.useState(false)
  //cross
  const [fromBlockchain, setFromBlockchain] = useState(
    props.item.blockchain ? props.item.blockchain : 'STELLAR'
  )
  const [toBlockchain, setToBlockchain] = useState('')
  const [toAccount, setToAccount] = useState(
    user.accounts.length >= 3 ? user.accounts[2].publicKey : ''
  )

  const [loadingMessage, setloadingMessage] = useState('please remain patient')
  //Confirm
  const [secret, setSecret] = useState('')
  //Confirm error
  const [secretError, SecretKeyError] = useState(false)
  const [fileError, setFileError] = useState(false)
  //hover and show
  const [hoverPassword, setHoverPassword] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [linkStyle, setLinkStyle] = useState({ cursor: 'pointer' })
  //cross Errors
  const [toBlockchainError, setToBlockchainError] = useState(false)
  const [toAccountError, setToAccountError] = useState(false)

  const { ...rest } = props
  const { enqueueSnackbar } = useSnackbar()
  const handleClose = () => {
    setOpen(false)
  }
  const handleOpen = () => {
    setOpen(true)
  }
  const exchange = async event => {
    event.preventDefault()
    //required check
    setToBlockchainError(toBlockchain === '' ? true : false)
    setToAccountError(toAccount === '' ? true : false)
    // }
    if (toBlockchain != '' && toAccount != '') {
      setLoading(true)
      setOpen(false)
      let secretKey = ''
      if (user.authType !== '2') {
        secretKey = decryptSecret(user.encryptedSecret, sha256(secret))
      }
      if (user.authType === '2') {
        secretKey = secret.toUpperCase()
      }
      if (
        !secretKey ||
        secretKey == '' ||
        secretKey == null ||
        secretKey == undefined
      ) {
        enqueueSnackbar('Invalid Credential', { variant: 'warning' })
      } else {
        const keypair = Keypair.fromSecret(secretKey)

        if (user.accounts.length < 3) {
          enqueueSnackbar('No Ethereum account found', { variant: 'warning' })
          setLoading(false)
          return
        }
        const ethSecret = decryptSecret(
          user.accounts[2].encryptedSecret,
          secretKey
        )
        // return
        setloadingMessage('Withdrawing Token')
        switch (props.item.blockchain) {
          case 'RINKEBY':
            var rinkeby = new Web3(web3Providers.providerRinkeby)
            const rinkebyAccount = rinkeby.eth.accounts.privateKeyToAccount(
              ethSecret
            )
            const contractRinkeby = new rinkeby.eth.Contract(
              ERC721ABI,
              props.item.contractId
            )
            const gasPriceRinkeby = await rinkeby.eth.getGasPrice()
            const gasEstimationRinkeby = await rinkeby.eth.estimateGas({
              to: props.item.contractId, // contract address
              data: contractRinkeby.methods
                .burn(props.item.tokenId)
                .encodeABI(),
              from: rinkebyAccount.address
            })
            const txRinkeby = {
              from: rinkebyAccount.address,
              // target address, this could be a smart contract address
              to: props.item.contractId,
              gas: gasEstimationRinkeby,
              gasPrice: new BN(gasPriceRinkeby),
              // this encodes the ABI of the method and the arguements
              data: contractRinkeby.methods.burn(props.item.tokenId).encodeABI()
            }
            const signedTxRinkeby = await rinkebyAccount.signTransaction(
              txRinkeby
            )
            const rawRinkeby = signedTxRinkeby.rawTransaction
            try {
              const sentTxRinkeby = await rinkeby.eth.sendSignedTransaction(
                rawRinkeby
              )
              if (
                sentTxRinkeby &&
                sentTxRinkeby.logs &&
                sentTxRinkeby.logs.length > 0
              ) {
                console.log(parseInt(sentTxRinkeby.logs[0].topics[3]))
                console.log(sentTxRinkeby.transactionHash)
              }
            } catch (err) {
              enqueueSnackbar(err.message, { variant: 'warning' })
              setLoading(false)
              console.log(err)
              return
            }
            break
          case 'BSCTESTNET':
            var bscTestnet = new Web3(web3Providers.providerBscTestNet)
            const bscTestnetAccount = bscTestnet.eth.accounts.privateKeyToAccount(
              ethSecret
            )
            const bscTestnetContract = new bscTestnet.eth.Contract(
              ERC721ABI,
              props.item.contractId
            )
            const bscTestNetGasPrice = await bscTestnet.eth.getGasPrice()
            const bscTestNetGasEstimation = await bscTestnet.eth.estimateGas({
              to: props.item.contractId, // contract address
              data: bscTestnetContract.methods
                .burn(props.item.tokenId)
                .encodeABI(),
              from: bscTestnetAccount.address
            })

            const txBscTestnet = {
              from: bscTestnetAccount.address,
              // target address, this could be a smart contract address
              to: props.item.contractId,
              gas: bscTestNetGasEstimation,
              gasPrice: new BN(bscTestNetGasPrice),
              // this encodes the ABI of the method and the arguements
              data: bscTestnetContract.methods
                .burn(props.item.tokenId)
                .encodeABI()
            }

            const signedTxBscTestnet = await bscTestnetAccount.signTransaction(
              txBscTestnet
            )

            const rawBscTestnet = signedTxBscTestnet.rawTransaction
            try {
              const sentTxBscTestnet = await bscTestnet.eth.sendSignedTransaction(
                rawBscTestnet
              )
              if (
                sentTxBscTestnet &&
                sentTxBscTestnet.logs &&
                sentTxBscTestnet.logs.length > 0
              ) {
                console.log(parseInt(sentTxBscTestnet.logs[0].topics[3]))
                console.log(sentTxBscTestnet.transactionHash)
              }
            } catch (err) {
              enqueueSnackbar(err.message, { variant: 'warning' })
              setLoading(false)

              console.log(err)
              return
            }
            break
          case 'ETHEREUM':
            var ethereum = new Web3(web3Providers.providerEthereum)
            const ethereumAccount = ethereum.eth.accounts.privateKeyToAccount(
              ethSecret
            )
            const contractEthereum = new ethereum.eth.Contract(
              ERC721ABI,
              props.item.contractId
            )
            const gasPriceEthereum = await ethereum.eth.getGasPrice()
            const gasEstimationEthereum = await ethereum.eth.estimateGas({
              to: props.item.contractId, // contract address
              data: contractEthereum.methods
                .burn(props.item.tokenId)
                .encodeABI(),
              from: ethereumAccount.address
            })
            const txEthereum = {
              from: ethereumAccount.address,
              // target address, this could be a smart contract address
              to: props.item.contractId,
              gas: gasEstimationEthereum,
              gasPrice: new BN(gasPriceEthereum),
              // this encodes the ABI of the method and the arguements
              data: contractEthereum.methods
                .burn(props.item.tokenId)
                .encodeABI()
            }
            const signedTxEthereum = await ethereumAccount.signTransaction(
              txEthereum
            )
            const rawEthereum = signedTxEthereum.rawTransaction
            try {
              const sentTxEthereum = await ethereum.eth.sendSignedTransaction(
                rawEthereum
              )
              if (
                sentTxEthereum &&
                sentTxEthereum.logs &&
                sentTxEthereum.logs.length > 0
              ) {
                console.log(parseInt(sentTxEthereum.logs[0].topics[3]))
                console.log(sentTxEthereum.transactionHash)
              }
            } catch (err) {
              enqueueSnackbar(err.message, { variant: 'warning' })
              setLoading(false)

              console.log(err)
              return
            }
            break
          case 'BSC':
            var bsc = new Web3(web3Providers.providerBsc)
            const bscAccount = bsc.eth.accounts.privateKeyToAccount(ethSecret)
            const bscContract = new bsc.eth.Contract(
              ERC721ABI,
              props.item.contractId
            )
            const bscGasPrice = await bsc.eth.getGasPrice()
            const bscGasEstimation = await bsc.eth.estimateGas({
              to: props.item.contractId, // contract address
              data: bscContract.methods.burn(props.item.tokenId).encodeABI(),
              from: bscAccount.address
            })

            const txBsc = {
              from: bscAccount.address,
              // target address, this could be a smart contract address
              to: props.item.contractId,
              gas: bscGasEstimation,
              gasPrice: new BN(bscGasPrice),
              // this encodes the ABI of the method and the arguements
              data: bscContract.methods.burn(props.item.tokenId).encodeABI()
            }

            const signedTxBsc = await bscAccount.signTransaction(txBsc)

            const rawBsc = signedTxBsc.rawTransaction
            try {
              const sentTxBsc = await bsc.eth.sendSignedTransaction(rawBsc)
              if (sentTxBsc && sentTxBsc.logs && sentTxBsc.logs.length > 0) {
                console.log(parseInt(sentTxBsc.logs[0].topics[3]))
                console.log(sentTxBsc.transactionHash)
              }
            } catch (err) {
              enqueueSnackbar(err.message, { variant: 'warning' })
              setLoading(false)
              console.log(err)
              return
            }
            break;
          case 'MATIC':
            var matic = new Web3(web3Providers.providerMatic)
            const maticAccount = matic.eth.accounts.privateKeyToAccount(ethSecret)
            const maticContract = new matic.eth.Contract(
              ERC721ABI,
              props.item.contractId
            )
            const maticGasPrice = await matic.eth.getGasPrice()
            const maticGasEstimation = await matic.eth.estimateGas({
              to: props.item.contractId, // contract address
              data: maticContract.methods.burn(props.item.tokenId).encodeABI(),
              from: maticAccount.address
            })

            const txMatic = {
              from: maticAccount.address,
              // target address, this could be a smart contract address
              to: props.item.contractId,
              gas: maticGasEstimation,
              gasPrice: new BN(maticGasPrice),
              // this encodes the ABI of the method and the arguements
              data: maticContract.methods.burn(props.item.tokenId).encodeABI()
            }

            const signedTxMatic = await maticAccount.signTransaction(txMatic)

            const rawMatic = signedTxMatic.rawTransaction
            try {
              const sentTxMatic = await matic.eth.sendSignedTransaction(rawMatic)
              if (sentTxMatic && sentTxMatic.logs && sentTxMatic.logs.length > 0) {
                console.log(parseInt(sentTxMatic.logs[0].topics[3]))
                console.log(sentTxMatic.transactionHash)
              }
            } catch (err) {
              enqueueSnackbar(err.message, { variant: 'warning' })
              setLoading(false)
              console.log(err)
              return
            }
            break;
          default:
            return
        }
        setloadingMessage('Submitting Modifications to IPFS')

        const ipfsData = await getIPFSData(
          `https://niftron.infura-ipfs.io/ipfs/${props.item.ipfsHash}`
        )
        ipfsData.previousChain = fromBlockchain
        ipfsData.previousTokenId = props.item.tokenId
        const ipfsHash = await AddToIPFS(JSON.stringify(ipfsData))
        setloadingMessage('Submitting Exchange Request to Niftron')
        let contractId = ''
        switch (toBlockchain) {
          case 'BSC':
            contractId =
              props.item.assetIssuer == projectIssuers.TLD
                ? contracts.BSC.EXIPTLD
                : contracts.BSC.EXIPDOMAIN
            break
          case 'BSCTESTNET':
            contractId =
              props.item.assetIssuer == projectIssuers.TLD
                ? contracts.BSCTESTNET.EXIPTLD
                : contracts.BSCTESTNET.EXIPDOMAIN
            break
          case 'ETHEREUM':
            contractId =
              props.item.assetIssuer == projectIssuers.TLD
                ? contracts.ETHEREUM.EXIPTLD
                : contracts.ETHEREUM.EXIPDOMAIN
            break
          case 'RINKEBY':
            contractId =
              props.item.assetIssuer == projectIssuers.TLD
                ? contracts.RINKEBY.EXIPTLD
                : contracts.RINKEBY.EXIPDOMAIN
            break;
          case 'MATIC':
            contractId =
              props.item.assetIssuer == projectIssuers.TLD
                ? contracts.MATIC.EXIPTLD
                : contracts.MATIC.EXIPDOMAIN
            break
        }
        const response = await crossExchangeExpereiment({
          crossType: fromBlockchain + 'TO' + toBlockchain,
          sender: user.publicKey,
          assetCode: props.item.assetCode,
          assetIssuer: props.item.assetIssuer,
          assetCount: props.item.assetCount,
          tokenName: props.item.tokenName,
          previewUrl: props.item.previewUrl,
          contractId,
          contract: ERC721ABI,
          toAccount,
          toBlockchain,
          fromBlockchain: props.item.blockchain
            ? props.item.blockchain
            : 'STELLAR',
          ipfsHash: ipfsHash ? ipfsHash : ''
        })
        switch (response) {
          case 200:
            enqueueSnackbar('Successfully Submitted', { variant: 'success' })
            break
          case 201:
            enqueueSnackbar('User Not Found', { variant: 'warning' })
            break
          case 202:
            enqueueSnackbar('Token Not Found', { variant: 'warning' })
            break
          case 203:
            enqueueSnackbar('Insufficient Fund in Account', {
              variant: 'warning'
            })
            break
          case 400:
            enqueueSnackbar('Failed', { variant: 'error' })
            break
          case null:
            enqueueSnackbar('Failed', { variant: 'error' })
        }
      }

      //   enqueueSnackbar('Exchange initiated', { variant: 'success' })
      setLoading(false)
    }
  }
  const confirmAction = e => {
    e.preventDefault()
    setToBlockchainError(toBlockchain === '' ? true : false)
    setToAccountError(toAccount === '' ? true : false)
    if (toBlockchain != '' && toAccount != '') {
      handleOpen()
    }
  }
  const AddToIPFS = async stringToUse => {
    const formData = new FormData()
    formData.append('base64', stringToUse)
    const res = await axios.post(
      'https://ipfs.infura.io:5001/api/v0/add',
      formData,
      {
        headers: {
          "Authorization": "Basic MkRDUm14VDFwV3FWYkR3cHRzcWdKcVQ5V3d0OmQyYWI4NWMyYmE0ZmRmZjlhODRkOWVlMTc3MDM3OGE2",
          "User-Agent": "",

          // 'Content-Length': file.length,
          "Content-Type": "multipart/form-data",
        },
      }
    )
    if (res === null) {
      return null
    }
    return res.data.Hash
  }
  const getIPFSData = async url => {
    try {
      const res = await axios.get(url)
      if (res === null) {
        throw Error('Failed to add data to IPFS')
      }
      return res.data
    } catch (er) {
      console.log(er)
      return null
    }
  }
  return (
    <div className={classes.container}>
      <Dialog
        maxWidth='sm'
        open={open}
        onClose={handleClose}
        aria-labelledby='responsive-dialog-title'
        scroll={'body'}
      >
        <DialogTitle id='responsive-dialog-title'>
          {'Please Confirm Action'}
        </DialogTitle>
        <DialogContent dividers>
          <form
            style={
              loading
                ? {
                  filter: 'blur(1px)',
                  '-webkit-filter': 'blur(1px)'
                }
                : null
            }
            onSubmit={exchange}
          >
            <CustomInput
              error={secretError}
              labelText={user.authType === '2' ? 'Secret Key *' : 'Password *'}
              id='tokenName'
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: !showPassword ? 'password' : 'text',
                endAdornment: (
                  <InputAdornment position='end'>
                    {hoverPassword && (
                      <Icon
                        style={linkStyle}
                        onMouseEnter={() => {
                          setHoverPassword(true)
                        }}
                        onMouseLeave={() => {
                          setHoverPassword(false)
                        }}
                        className={classes.inputIconsColor}
                        onClick={() => {
                          setShowPassword(!showPassword)
                        }}
                      >
                        {showPassword ? 'visibilityoff' : 'visibility'}
                      </Icon>
                    )}
                    {!hoverPassword && (
                      <Icon
                        style={linkStyle}
                        className={classes.inputIconsColor}
                        onMouseEnter={() => {
                          setHoverPassword(true)
                        }}
                        onMouseLeave={() => {
                          setHoverPassword(false)
                        }}
                      >
                        lock
                      </Icon>
                    )}
                  </InputAdornment>
                ),
                required: true,
                onChange: function (e) {
                  setSecret(e.target.value)
                  SecretKeyError(e.target.value === '' ? true : false)
                },
                value: secret
              }}
            />
            {user.authType === '2' && (
              <>
                <Grid
                  container
                  spacing={0}
                  direction='column'
                  alignItems='center'
                  justify='center'
                >
                  <Grid item xs={3}>
                    or{' '}
                  </Grid>
                </Grid>
                <Dropzone
                  onDrop={acceptedFiles => {
                    const reader = new FileReader()
                    reader.addEventListener('load', async () => {
                      // setFile(reader.result)
                      setSecret(JSON.parse(atob(reader.result)).secretKey)
                    })
                    reader.readAsText(acceptedFiles[0])
                  }}
                  multiple={false}
                  accept={['.niftron']}
                >
                  {({ getRootProps, getInputProps }) => (
                    <Link>
                      <section className={'container'}>
                        <div
                          {...getRootProps({
                            className: fileError
                              ? 'dropzone2Error'
                              : 'dropzone2'
                          })}
                        >
                          <input {...getInputProps()} />
                          <p>Drop or select your niftron credential file</p>
                        </div>
                      </section>
                    </Link>
                  )}
                </Dropzone>
              </>
            )}
            <Button
              color={'info'}
              onClick={exchange}
              type={'submit'}
              disabled={loading}
            >
              Confirm
            </Button>
          </form>
        </DialogContent>
      </Dialog>
      <Card>
        <form className={classes.form} onSubmit={confirmAction}>
          <CardHeader color='info' className={classes.cardHeader}>
            <h3>Cross Exchange</h3>
          </CardHeader>
          <CardBody>
            <div
              style={
                loading
                  ? {
                    filter: 'blur(1px)',
                    '-webkit-filter': 'blur(1px)'
                  }
                  : null
              }
            >
              <GridContainer justify='center'>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    color='info'
                    // error={assetCountError}
                    labelText='From Blockchain'
                    id='fromBlockchain'
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: 'text',
                      required: true,
                      value: fromBlockchain,
                      disabled: props.item.tokenType === 'NFT' ? false : true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <CustomInputSelect
                    //info
                    error={toBlockchainError}
                    options={
                      <Select
                        // disabled
                        value={toBlockchain}
                        onChange={e => {
                          setToBlockchain(e.target.value)
                          setToBlockchainError(
                            e.target.value === '' ? true : false
                          )
                          if (e.target.value == 'STELLAR') {
                            setToAccount(user.publicKey ? user.publicKey : '')
                          } else {
                            setToAccount(
                              user.accounts.length >= 3
                                ? user.accounts[2].publicKey
                                : ''
                            )
                          }
                        }}
                        displayEmpty
                        className={classes.selectEmpty}
                      >
                        <MenuItem value=''>
                          <em>Choose Your Blockchain</em>
                        </MenuItem>
                        {props.item.blockchain != 'STELLAR' && (
                          <MenuItem value={'STELLAR'} >STELLAR</MenuItem>
                        )}
                        {props.item.blockchain != 'BSC' && (
                          <MenuItem value={'BSC'} disabled>
                            BSC
                          </MenuItem>
                        )}
                        {props.item.blockchain != 'ETHEREUM' && (
                          <MenuItem value={'ETHEREUM'} disabled>
                            ETHEREUM
                          </MenuItem>
                        )}
                        {props.item.blockchain != 'MATIC' && (
                          <MenuItem value={'MATIC'} disabled>
                            BSC
                          </MenuItem>
                        )}

                        {/* {props.item.blockchain != 'BSCTESTNET' && (
                          <MenuItem value={'BSCTESTNET'}>BSCTESTNET</MenuItem>
                        )}
                        {props.item.blockchain != 'RINKEBY' && (
                          <MenuItem value={'RINKEBY'}>RINKEBY</MenuItem>
                        )} */}
                      </Select>
                    }
                    labelText='To Blockchain *'
                    id='blockchain'
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: 'text',
                      endAdornment: (
                        <InputAdornment position='end'>
                          <PublicIcon className={classes.inputIconsColor} />
                        </InputAdornment>
                      ),
                      required: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    color='info'
                    error={toAccountError}
                    labelText='To Account'
                    id='assetCount'
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: 'text',
                      required: true,
                      value: toAccount,
                      onChange: function (e) {
                        setToAccount(e.target.value)
                        var re = /^\d+$/
                        //required check
                        setToAccountError(e.target.value === '' ? true : false)
                      }
                      //   disabled: props.item.tokenType === 'NFT' ? false : true
                    }}
                  />
                </GridItem>
              </GridContainer>
            </div>
            {loading && (
              <>
                {loadingMessage}
                <LinearProgress />
              </>
            )}
          </CardBody>
          <CardFooter className={classes.cardFooter}>
            <Button
              color='info'
              size='lg'
              type={'submit'}
              //   onClick={confirmAction}
              disabled={loading || toAccountError || toBlockchainError}
            >
              Exchange
            </Button>
          </CardFooter>
        </form>
      </Card>
    </div>
  )
}

export default CrossExhangeTokenComponent
