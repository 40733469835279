import axios from "axios";
import { niftronTokenLambda } from "variables/constants";
// import { NiftronIssuerPublicKey } from "variables/constants";
// import { Server } from "stellar-sdk";
// import store from "redux/reducers";

// let StellarUrl = store.getState().StellarUrl;
// let StellarUrlTest = store.getState().StellarUrlTest;

// store.subscribe(() => {
//   StellarUrl = store.getState().StellarUrl;
//   StellarUrlTest = store.getState().StellarUrlTest;
// });

export async function AddAccess(accessModel) {
  try {
    let token;
    if (localStorage.getItem("token") != null) {
      token = localStorage.getItem("token");
    }
    let postBody = accessModel;
    const res = await axios.post(
      niftronTokenLambda + "/tokens/access",
      postBody,
      {
        headers: {
          Authorization: "bearer " + token,
          "Content-Type": "application/json"
        }
      }
    );

    if (res === null) {
      return null;
    }
    return res.status;
  } catch (err) {
    return null;
  }
}

export async function ListAccessByReceiver(id, limit, page) {
  try {
    // let token;
    // if (localStorage.getItem("token") != null) {
    //   token = localStorage.getItem("token");
    // }
    let query = "";
    if (limit > 0 && page > 0) {
      query += `limit=${limit}&page=${page}`;
    }
    const res = await axios.get(
      `${niftronTokenLambda}/users/${id}/access?${query}`,
      {
        headers: {
          // 'Authorization': "bearer " + token,
          "Content-Type": "application/json"
        }
      }
    );

    if (res === null) {
      return null;
    }
    return res.data;
  } catch (err) {
    return null;
  }
}
