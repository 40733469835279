/* eslint-disable */

import React, { useEffect, useState } from "react";
// react plugin for creating charts
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinIcon,
  LinkedinShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
  EmailIcon,
  EmailShareButton,
} from "react-share";
import Art from "views/Token/Components/Art";
import Ticket from "views/Token/Components/Ticket";
import Certificate from "views/Token/Components/Certificate";
import Badge from "views/Token/Components/Badge";
import Record from "views/Token/Components/Record";
import Token from "views/Token/Components/Token";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardToken from "components/Card/CardToken.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

export default function TokenMorpher(props) {
  let issuerAlias = "";
  const issuerNames = props.item.issuerAlias;
  const issuerNameBreakdown = issuerNames.split(" ");
  issuerNameBreakdown.map((issuer) => {
    issuerAlias += issuer.charAt(0).toUpperCase() + issuer.substring(1) + " ";
  });

  // const issuerAlias = props.item.issuerAlias.charAt(0).toUpperCase() + props.item.issuerAlias.substring(1);
  const userAlias =
    props.alias.charAt(0).toUpperCase() + props.alias.substring(1);
  const tokenName =
    props.item.tokenName.charAt(0).toUpperCase() +
    props.item.tokenName.substring(1);

  const [tokenDetails, setTokenDetails] = useState(
    tokenName + " was issued by " + issuerAlias + " to " + userAlias
  );

  return (
    <div>
      {props.item.category === "DIGITALART" && <Art item={props.item} />}
      {props.item.category === "CERTIFICATE" && (
        <Certificate item={props.item} />
      )}
      {props.item.category === "BADGE" && <Badge item={props.item} />}
      {props.item.category === "TOKEN" && <Token item={props.item} />}{" "}
      {props.item.category === "RECORD" && <Record item={props.item} />}
      {props.item.category === "TICKET" && (
        <Ticket
          item={props.item}
          eventName={props.item.eventName}
          edition={props.item.edition}
          image={props.item.previewUrl}
          eventDate={props.item.eventDate}
          location={props.item.location}
          price={props.item.price}
          niftronId={props.item._id}
          createdAt={props.item.createdAt}
        />
      )}
      {/* //Corda Certified Developer was issued by R3 to Azeem Ashraf. */}
      {/* url={String(window.location)} -- share current url */}
      {/* https://www.npmjs.com/package/react-share */}
      <Card>
        <CardHeader>
          Share Your{" "}
          {props.item.category.charAt(0) +
            props.item.category.substring(1).toLowerCase()}
        </CardHeader>
        <CardBody>
          <FacebookShareButton
            url={`http://explorer.niftron.com/token/${props.item.assetCode}/${
              props.publicKey
            }/${btoa(tokenDetails)}`}
            quote={`${tokenDetails}`}
            hashtag="Niftron"
          >
            <FacebookIcon size={32} round={false} borderRadius={6} />
          </FacebookShareButton>

          <LinkedinShareButton
            url={`http://explorer.niftron.com/token/${props.item.assetCode}/${
              props.publicKey
            }/${btoa(tokenDetails)}`}
            source="Niftron"
            summary={`${tokenDetails}`}
            title={`${tokenDetails}`}
          >
            <LinkedinIcon size={32} round={false} borderRadius={6} />
          </LinkedinShareButton>

          <FacebookMessengerShareButton
            url={`http://explorer.niftron.com/token/${props.item.assetCode}/${
              props.publicKey
            }/${btoa(tokenDetails)}`}
            appId="862394367580761"
            redirectUri={`https://profile.niftron.com/token/${props.item.assetCode}`}
            to=""
          >
            <FacebookMessengerIcon size={32} round={false} borderRadius={6} />
          </FacebookMessengerShareButton>

          <TwitterShareButton
            url={`http://explorer.niftron.com/token/${props.item.assetCode}/${
              props.publicKey
            }/${btoa(tokenDetails)}`}
            title={`${tokenDetails}`}
            hashtags={["Blockchain", "Niftron"]}
          >
            <TwitterIcon size={32} round={false} borderRadius={6} />
          </TwitterShareButton>

          <WhatsappShareButton
            url={`http://explorer.niftron.com/token/${props.item.assetCode}/${
              props.publicKey
            }/${btoa(tokenDetails)}`}
            title={`${tokenDetails}`}
          >
            <WhatsappIcon size={32} round={false} borderRadius={6} />
          </WhatsappShareButton>

          <TelegramShareButton
            url={`http://explorer.niftron.com/token/${props.item.assetCode}/${
              props.publicKey
            }/${btoa(tokenDetails)}`}
            title={`${tokenDetails}`}
          >
            <TelegramIcon size={32} round={false} borderRadius={6} />
          </TelegramShareButton>

        </CardBody>
      </Card>
    </div>
  );
}
