
import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import { Link } from "react-router-dom";
import { useSnackbar } from 'notistack';

import AccountRequest from "views/AuthPage/PasswordReset/AccountRequest.js";
import SecretReset from "views/AuthPage/PasswordReset/SecretReset.js";
import OtpReset from "views/AuthPage/PasswordReset/OtpReset.js";


const useStyles = makeStyles(styles);

function PasswordReset(props) {
  const [option, setOption] = useState('ACCOUNTREQUEST');
  const [user, setUser] = useState(null);
  // const [user, setUser] = useState({ alias: "alias", encryptedRecoverySecret: "encryptedRecoverySecret" });

  const [showOtpReset, setShowOtpResetKey] = useState(false);
  const [showSecretReset, setShowSecretReset] = useState(false);
  const classes = useStyles();

  const { ...rest } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  return (
    <>
      {option === 'ACCOUNTREQUEST' && (
        <AccountRequest previousRoute={props.previousRoute}
          setOption={setOption} setUser={setUser}
          setShowOtpResetKey={setShowOtpResetKey}
          setShowSecretReset={setShowSecretReset}
          setStep={props.setStep} history={props.history} />
      )}
      {option === 'SECRETRESET' && (
        <SecretReset previousRoute={props.previousRoute}
          user={user} showOtpReset={showOtpReset}  setOption={setOption} 
          showSecretReset={showSecretReset}
          setStep={props.setStep} history={props.history} />
      )}
      {option === 'OTPRESET' && (
        <OtpReset previousRoute={props.previousRoute}
          user={user} showOtpReset={showOtpReset}  setOption={setOption} 
          showSecretReset={showSecretReset}
          setStep={props.setStep} history={props.history} />
      )}

    </ >
  );
}

export default PasswordReset