import { SearchOrGetAccounts } from "services/UserManagement";

import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

// function sleep(delay = 0) {
//     return new Promise((resolve) => {
//         setTimeout(resolve, delay);
//     });
// }

export default function PickUser(props) {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const loading = open && options.length === 0;

    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            const response = await SearchOrGetAccounts(props.value);
            // await sleep(1e3); // For demo purposes.
            if (response != null) {
                //console.log(response)
                setOptions(response);
            }
        })();
        return () => {
            active = false;
        };
    }, [loading]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return (
        <Autocomplete
            id="asynchronous-demo"
            // style={{ width: 300 }}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            // getOptionSelected={(option, value) => {
            //     //console.log("value " + value)
            //     //console.log("option " + option)
            //     // props.setValue(value.publicKey)
            // }}
            onChange={(e, value) => {
                // //console.log(value.publicKey)
                if (value) {
                    //console.log(value.publicKey)

                    props.setValue(value.publicKey)
                }

            }}
            getOptionLabel={(option) => option.alias}
            options={options}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Recipient *"
                    // variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}