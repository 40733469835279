import { SearchOrGetAccounts, getUserSession } from "services/UserManagement";

import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import CSVReader from "react-csv-reader";
import Grid from '@material-ui/core/Grid';

// function sleep(delay = 0) {
//     return new Promise((resolve) => {
//         setTimeout(resolve, delay);
//     });
// }

export default function PickUser(props) {
    const [session, setSession] = React.useState(getUserSession());
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const loading = open && options.length === 0;

    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            // //console.log(session)
            const response = await SearchOrGetAccounts(props.value);
            // await sleep(1e3); // For demo purposes.
            if (response != null) {
                let arrayUsers = []
                response.map((user, indexU) => {
                    let available = true;
                    props.receivers.map((receiver, indexR) => {
                        if (receiver.publicKey === user.publicKey) {
                            available = false
                        }
                    })
                    if (session.publicKey === user.publicKey) {
                        available = false
                    }
                    if (available) {
                        arrayUsers.push(user)
                    }
                })
                //console.log(arrayUsers)
                setOptions(arrayUsers);
            }
        })();
        return () => {
            active = false;
        };
    }, [loading]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    const handleForce = async (data, fileInfo) => {
        // //console.log(data, fileInfo);

        const response = await SearchOrGetAccounts("");
        // await sleep(1e3); // For demo purposes.
        if (response != null) {
            let arrayUsers = []
            response.map((user, indexU) => {
                let available = true;
                props.receivers.map((receiver, indexR) => {
                    if (receiver.publicKey === user.publicKey) {
                        available = false
                    }
                })
                if (session.publicKey === user.publicKey) {
                    available = false
                }
                if (available) {
                    data.map((item, indexZ) => {
                        if (item.alias.toLowerCase() === user.alias.toLowerCase()) {
                            arrayUsers.push(user)
                        }
                    })
                }
            })
            //console.log(arrayUsers)
            props.setValue(arrayUsers)

            // setOptions(arrayUsers);
        }

    }

    const papaparseOptions = {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
    };
    return (
        <div>
            <Autocomplete
                id="asynchronous-demo"
                multiple
                // style={{ width: 300 }}
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                // getOptionSelected={(option, value) => {
                //     //console.log("value " + value)
                //     //console.log("option " + option)
                //     // props.setValue(value.publicKey)
                // }}
                onChange={(e, value) => {
                    // //console.log(value)
                    // if (value && value.length > 0) {
                    // //console.log(value)
                    props.setValue(value)
                    // }

                }}
                getOptionLabel={(option) => option.alias}
                options={options}
                loading={loading}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Select Recipients *"
                        // variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
            >

                <Grid item xs={3}>
                    or                  </Grid>

            </Grid>
            <section className={"container"}>
                <CSVReader
                    inputId="sss"
                    cssClass="dropzone2"
                    label={<p>Select or Drop a CSV with the following field: alias</p>}
                    onFileLoaded={handleForce}
                    parserOptions={papaparseOptions}
                />

            </section>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
            >

                <Grid item xs={12}>
                    {props.receivers.length > 0 && <p>{`${props.receivers.length} ${props.receivers.length>1?"recipients":"recipient"} selected`}</p>}
                </Grid>

            </Grid>
        </div>

    );
}