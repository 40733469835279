/* eslint-disable */
import React, { useEffect, useState } from "react";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import TokenMorpher from "views/VaultList/Components/TokenMorpher";
import TokenMaticNiftron from "views/VaultList/Components/TokenMaticNiftron";

import DummyToken from "views/VaultList/Components/DummyToken";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { getUserSession } from "services/UserManagement";
import {
  getTokenByIdList,
  getTokenBalanceFromStellar,
} from "services/TokenManagement";
// import Paginations from "components/Pagination/Pagination.js";
import Pagination from "@material-ui/lab/Pagination";
// import Grid from '@material-ui/core/Grid';
import { withRouter, Link } from "react-router-dom";
import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Web3 from "web3";
// const providerB = new Web3.providers.HttpProvider(
//   'https://bsc-dataseed1.ninicoin.io',
//   {
//     keepAlive: true
//   }
// )
// const providerE = new Web3.providers.HttpProvider(
//   'https://rinkeby.infura.io/v3/1ae5799b9f6c4321951ad280f2b82a0f',
//   {
//     keepAlive: true
//   }
// )
// var bscWeb3 = new Web3(providerB)
// var ethWeb3 = new Web3(providerE)

import { web3Providers } from "../../variables/constants";
var bscWeb3 = new Web3(web3Providers.providerBsc);
var maticWeb3 = new Web3(web3Providers.providerMatic);
const ERC721ABI = require("./ERC721.json");

const useStyles = makeStyles(styles);

const ethContractList = [
  "0x239F16F0F107C0AfCB39cF1a9d57Be2318C1A409",
  "0x3280d8E1d5b055542d1367A440c375e54390b13D",
];
const bscContractList = [
  "0x239F16F0F107C0AfCB39cF1a9d57Be2318C1A409",
  "0x3280d8E1d5b055542d1367A440c375e54390b13D",
];
const maticContractList = [
  // "0x18bb126dcc43b87e091c16f354d54e350400bf42",
  "0xf32cf42F54c611072137D712683671c8A6B2aa6F",
  "0x648eA0BE9Dc4F99c467268d5b384B251e1be77E7",
];
// const contracts = {
//   ETHEREUM: {
//     EXIPTLD: '',
//     EXIPDOMAIN: '',
//     EXIPTOKEN: ''
//   },
//   RINKEBY: {
//     EXIPTLD: '0x1646Ab4E0893E4f201F0a9E09eFb592161Fef13C',
//     EXIPDOMAIN: '0x330aF563E1F82b1fC85d191553A83Dc1E2c419EB',
//     EXIPTOKEN: '0xB01A149eb95db0E434d13498eE0F3A0d0239D34F'
//   },
//   BSC: {
//     EXIPTLD: '',
//     EXIPDOMAIN: '',
//     EXIPTOKEN: ''
//   },
//   BSCTESTNET: {
//     EXIPTLD: '0x6573a3AE3587A5DA4958954e2e0ADFC101150BFC',
//     EXIPDOMAIN: '0x1646Ab4E0893E4f201F0a9E09eFb592161Fef13C',
//     EXIPTOKEN: '0xc79A6AE6EC40B6ba364987Ae43726050788A5679'
//   }
// }
function VaultList(props) {
  const classes = useStyles();
  const [user, setUser] = useState(getUserSession());
  const [tokens, setTokens] = useState([]);
  const [otherTokens, setotherTokens] = useState([]);
  const [paginatedTokens, setPaginatedTokens] = useState([]);
  const [loading, setloading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(12);
  const [loadingText, setLoadingText] = useState("loading");
  const [placeholder, setPlaceholder] = useState([
    0,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
  ]);

  const [tradable, setTradable] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setloading(true);

      const key = user.accounts[0].publicKey;
      setLoadingText("Retrieving Tokens From Multiple Blockchains");
      let IdList = [];
      let maticList = [];

      if (user.accounts && user.accounts.length >= 3) {
        const ethereumKey = user.accounts[2].publicKey;
        // console.log(ethereumKey)

        // bsc
        for (var j = 0; j < bscContractList.length; j++) {
          const contract = new bscWeb3.eth.Contract(
            ERC721ABI,
            bscContractList[j]
          );
          const tokenCount = await contract.methods
            .balanceOf(ethereumKey)
            .call({ from: ethereumKey });
          console.log(tokenCount);

          for (var i = 0; i < tokenCount; i++) {
            const tokenId = await contract.methods
              .tokenOfOwnerByIndex(ethereumKey, i)
              .call({ from: ethereumKey });
            IdList.push({
              issuer: bscContractList[j],
              id: tokenId,
              blockchain: "BSC",
            });
          }
        }

        // matic
        for (var j = 0; j < maticContractList.length; j++) {
          const maticContract = new maticWeb3.eth.Contract(
            ERC721ABI,
            maticContractList[j]
          );
          const tokenCount = await maticContract.methods
            .balanceOf(ethereumKey)
            .call({ from: ethereumKey });
          console.log(tokenCount);

          for (var i = 0; i < tokenCount; i++) {
            const tokenId = await maticContract.methods
              .tokenOfOwnerByIndex(ethereumKey, i)
              .call({ from: ethereumKey });
            IdList.push({
              issuer: maticContractList[j],
              id: tokenId,
              blockchain: "MATIC",
            });
          }
        }

        
        //only for ICTA MATIC
        // for (var i = 0; i < maticContractList.length; i++) {
        //   const maticContract = new maticWeb3.eth.Contract(
        //     ERC721ABI,
        //     maticContractList[i]
        //   );
        //   const tokenCount = await maticContract.methods
        //     .balanceOf(ethereumKey)
        //     .call({ from: ethereumKey });
        //   console.log(tokenCount);

        //   for (let j = 0; j < tokenCount; j++) {
        //     const tokenId = await maticContract.methods
        //       .tokenOfOwnerByIndex(ethereumKey, j)
        //       .call({ from: ethereumKey });
        //     const tokenURI = await maticContract.methods
        //       .tokenURI(tokenId)
        //       .call({ from: ethereumKey });

        //     maticList.push({
        //       contractId: maticContractList[i],
        //       tokenId,
        //       tokenURI,
        //       isMatic: true,
        //     });
        //   }
        // }

        // bscContractList.forEach(async contractId => {
        //   const contract = new bscWeb3.eth.Contract(ERC721ABI, contractId)
        //   const tokenCount = await contract.methods
        //     .balanceOf(ethereumKey)
        //     .call({ from: ethereumKey })
        //   console.log(tokenCount);

        //   for (let j = 0; j < tokenCount; j++) {
        //     const tokenId = await contract.methods
        //       .tokenOfOwnerByIndex(ethereumKey, j)
        //       .call({ from: ethereumKey })
        //     IdList.push({
        //       issuer: contractId,
        //       id: tokenId,
        //       blockchain: 'BSC'
        //     })
        //   }
        // })
        //only for ICTA MATIC

        // maticContractList.forEach(async contractId => {
        //   const maticContract = new maticWeb3.eth.Contract(ERC721ABI, contractId)
        //   const tokenCount = await maticContract.methods
        //     .balanceOf(ethereumKey)
        //     .call({ from: ethereumKey })
        //   console.log(tokenCount);

        //   for (let j = 0; j < tokenCount; j++) {
        //     const tokenId = await maticContract.methods
        //       .tokenOfOwnerByIndex(ethereumKey, j)
        //       .call({ from: ethereumKey })
        //     const tokenURI = await maticContract.methods
        //       .tokenURI(tokenId)
        //       .call({ from: ethereumKey })

        //    maticList.push({
        //       contractId,
        //       tokenId,
        //       tokenURI,
        //       isMatic:true
        //     })
        //   }
        // })

        //   ethContractList.forEach(async contractId => {
        //     const contract = new ethWeb3.eth.Contract(ERC721ABI, contractId)
        //     const tokenCount = await contract.methods
        //       .balanceOf(ethereumKey)
        //       .call({ from: ethereumKey })
        //     console.log(tokenCount);

        //     for (var i = 0; i < tokenCount; i++) {
        //       const tokenId = await contract.methods
        //         .tokenOfOwnerByIndex(ethereumKey, i)
        //         .call({ from: ethereumKey })
        //       IdList.push({
        //         issuer: contractId,
        //         id: tokenId
        //       })
        //     }
        //   })
      }

      const balances = await getTokenBalanceFromStellar(key);
      if (balances != null) {
        //console.log(balances)
        balances.forEach((token) => {
          IdList.push({
            id: token.assetCode,
            issuer: token.issuer,
            blockchain: "STELLAR",
          });
        });
      }

      console.log(IdList);
      setLoadingText("Retrieving MetaData From Niftron");
      console.log(IdList);
      let data = [];

      let result = await getTokenByIdList(
        IdList //.slice(limit * (page - 1), limit * (page - 1) + limit)
      );

      if (result != null) {
        if (result.data && result.data.length > 0) {
          if (pageCount != result.data.length / limit) {
            setPageCount(Math.ceil(result.data.length / limit));
          }

          data = result.data;
        }
        setloading(false);
      }

      const combined = maticList.concat(data);
      console.log(combined);
      setTokens(combined);
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      setPaginatedTokens(
        tokens.slice(limit * (page - 1), limit * (page - 1) + limit)
      );
    };
    fetchData();
  }, [page, limit, tokens]);
  return (
    <div>
      {loading && (
        <GridContainer justify="center" style={{ minHeight: 500 }}>
          {placeholder.map((i) => (
            <GridItem
              key={i}
              xs={12}
              sm={6}
              md={4}
              lg={3}
              className={"loadingCard"}
            >
              <DummyToken loadingText={loadingText} />
            </GridItem>
          ))}
        </GridContainer>
      )}
      {!loading && paginatedTokens.length > 0 && (
        <GridContainer justify="center" style={{ minHeight: 500 }}>
          {paginatedTokens.map((item, index) =>
            item.data ? (
              <GridItem key={index} xs={12} sm={6} md={4} lg={3}>
                <Link to={"/token/" + item.data._id}>
                  <TokenMorpher item={item.data} />
                </Link>
              </GridItem>
            ) : item.isMatic ? (
              <GridItem key={index} xs={12} sm={6} md={4} lg={3}>
                <Link to={"/nft/" + item.tokenId}>
                  <TokenMaticNiftron
                    tokenId={item.tokenId}
                    tokenURI={item.tokenURI}
                  />
                </Link>
              </GridItem>
            ) : null
          )}
        </GridContainer>
      )}
      {tokens.length > 0 && (
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Pagination
              count={pageCount}
              color="info"
              style={{ float: "right" }}
              onChange={(e, number) => {
                //console.log(number)
                setPage(number);
              }}
            />
          </GridItem>
        </GridContainer>
      )}
      {!loading && tokens.length === 0 && (
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info" className={classes.cardHeader}>
                <h3>Sorry! your vault is empty.</h3>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
      )}
    </div>
  );
}

export default withRouter(VaultList);
