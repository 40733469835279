import React, { useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import { useSnackbar } from "notistack";
// import Grid from '@material-ui/core/Grid';
// import Grid from "@material-ui/core/Grid";
// import Dropzone from "react-dropzone";
// import { Link } from "react-router-dom";

import styles from "assets/jss/material-kit-react/views/loginPage.js";
import { getUserSession } from "services/UserManagement.js";
import { claimCoupon } from "services/ClaimManagement";

import CustomInput from "components/CustomInput/CustomInput.js";

const useStyles = makeStyles(styles);

function ClaimNFT(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function() {
    setCardAnimation("");
  }, 400);

  const [user] = useState(getUserSession());
  // const [publicKey] = useState(user.publicKey);
  // const [ethPublicKey] = useState(user.accounts[2].publicKey);
  const [loading, setLoading] = useState(false);

  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState(false);

  // console.log(user.authType)
  const classes = useStyles();

  // const { ...rest } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { ...rest } = props;

  React.useEffect(() => {
    const fetchBalance = async () => {};
    fetchBalance();
  }, []);

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={10} lg={8}>
          {user && (
            <Card className={classes[cardAnimaton]}>
              <form
                type="submit"
                className={classes.form}
                onSubmit={async e => {
                  e.preventDefault();
                  try {
                    setLoading(true);

                    const res = await claimCoupon(user.publicKey, code);
                    console.log(res);
                    setLoading(false);

                    enqueueSnackbar("Claim request submitted successfully", {
                      variant: "success"
                    });
                  } catch (e) {
                    setLoading(false);

                    enqueueSnackbar(e.message, { variant: "warning" });

                    console.log(e);
                  }
                }}
              >
                <CardHeader color="info" className={classes.cardHeader}>
                  <h3>Claim NFT</h3>
                  {/* <h5>powered by Stellar</h5> */}
                </CardHeader>
                <CardBody>
                  <CustomInput
                    //info
                    error={codeError}
                    labelText="Code *"
                    id="code"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: loading,
                      type: "text",
                      autoComplete: "off",
                      onChange: function(e) {
                        setCode(e.target.value);
                        setCodeError(e.target.value === "" ? true : false);
                      },
                      value: code
                    }}
                  />
                </CardBody>
                <CardFooter className={classes.cardFooter}>
                  <Button
                    color="info"
                    size="lg"
                    disabled={code == "" || loading}
                    onClick={async e => {
                      e.preventDefault();
                      try {
                        setLoading(true);

                        const res = await claimCoupon(user.publicKey, code);
                        console.log(res);
                        setLoading(false);

                        enqueueSnackbar(
                          "Claim request submitted successfully",
                          {
                            variant: "success"
                          }
                        );
                      } catch (e) {
                        setLoading(false);

                        enqueueSnackbar(e.message, { variant: "warning" });

                        console.log(e);
                      }
                    }}
                  >
                    Request Claim
                  </Button>
                </CardFooter>
              </form>
            </Card>
          )}
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default ClaimNFT;
