
import React, { useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

import Email from "@material-ui/icons/Email";
import Typography from '@material-ui/core/Typography';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";


import { requestPasswordReset } from "services/UserManagement.js";
import LinearProgress from '@material-ui/core/LinearProgress';
import { useSnackbar } from 'notistack';

import styles from "assets/jss/material-kit-react/views/loginPage.js";
import { useSelector } from 'react-redux'


const useStyles = makeStyles(styles);

function AccountRequest(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 400);
  const loadingMessage = useSelector(state => state.LoadingMessage)

  const [key, setKey] = useState("");
 
  //Errors 
  const [keyError, setKeyError] = useState(false);

  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  const { ...rest } = props;
  const { enqueueSnackbar } = useSnackbar();

  const getUserAccount = async (event) => {
    event.preventDefault()
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //required check
    setKeyError(key != "" || re.test(key) ? false : true);
    if (key != "") {
      setLoading(true)
      const response = await requestPasswordReset(key)
      switch (response.code) {
        case 200:
          props.setUser(response.data);
          //console.log(response.data)
          if (response.data.authType === "0") {
            props.setOption("OTPRESET");
          }
          if (response.data.authType === "1") {
            props.setOption("SECRETRESET");
          }; break;
        case 201: enqueueSnackbar("Account Not Found", { variant: "warning" }); break;
        case 202: enqueueSnackbar("High Privacy Account Cannot Reset Password", { variant: "warning" }); break;
        case 400: enqueueSnackbar("Account Info Retrieval Failed", { variant: "error" }); break;
        case null: enqueueSnackbar("Account Info Retrieval Failed", { variant: "error" });
      }
      setLoading(false)
    }
  };

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={5}>
          <Card className={classes[cardAnimaton]}>
            <form className={classes.form} onSubmit={getUserAccount}
              >
              <CardHeader color="info" className={classes.cardHeader}><h3>Password Reset</h3></CardHeader>
              <CardBody>
              <div style={loading ? {
                  filter: "blur(1px)",
                  "-webkit-filter": "blur(1px)"
                } : null}>  {props.warning && <SnackbarContent message={props.warning}
                  icon={AddAlert} color="warning" />}
                <CustomInput color="info"
                  error={keyError} labelText="Alias/Email/Publickey *"
                  id="key" formControlProps={{ fullWidth: true }}
                  inputProps={{
                    type: "email",
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputIconsColor} />
                      </InputAdornment>
                    ), required: true,
                    onChange: function (e) {
                      setKey(e.target.value)
                      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                      //required check
                      setKeyError(e.target.value != "" || re.test(e.target.value) ? false : true);
                    }
                  }}
                /></div>
              
                {loading && <LinearProgress />}
                {loading && <p className={classes.divider}>{loadingMessage}</p>}

              </CardBody>
              <CardFooter className={classes.cardFooter}>
                <Button color="info" size="lg" type={"submit"} onClick={getUserAccount}
                  disabled={loading || keyError}>Request Password Reset</Button>
              </CardFooter>
              <Typography gutterBottom variant="body2" color="textSecondary" align={"center"}>
                Remember your password?<Button simple color="info" onClick={(e) => {
                  e.preventDefault()
                  props.setStep('LOGIN')
                }}>Login</Button>
              </Typography>
              <Typography gutterBottom variant="body2" color="textSecondary" align={"center"}>
                A high privacy niftroner?<Button simple color="info" onClick={(e) => {
                  e.preventDefault()
                  props.setStep('SECRETLOGIN')
                }}>Use secret key login</Button>
              </Typography>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default AccountRequest