/* eslint-disable */

import React from "react";
// react plugin for creating charts
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Art from "views/VaultList/Components/Art";
import Ticket from "views/VaultList/Components/Ticket";
import Certficate from "views/VaultList/Components/Certficate";
import Badge from "views/VaultList/Components/Badge";
import Record from "views/VaultList/Components/Record";
import Token from "views/VaultList/Components/Token";

const useStyles = makeStyles(styles);

export default function TokenMorpher(props) {
  const classes = useStyles();
  return (
    <div>
      {props.item.category === "DIGITALART" && <Art item={props.item} />}
      {props.item.category === "CERTIFICATE" && (
        <Certficate item={props.item} />
      )}
      {props.item.category === "BADGE" && <Badge item={props.item} />}
      {props.item.category === "TOKEN" && <Token item={props.item} />}
      {props.item.category === "RECORD" && <Record item={props.item} />}
      {props.item.category === "TICKET" && (
        <Ticket
          item={props.item}
          eventName={props.item.eventName}
          edition={props.item.edition}
          image={props.item.previewUrl}
          eventDate={props.item.eventDate}
          location={props.item.location}
          price={props.item.price}
          niftronId={props.item._id}
          createdAt={props.item.createdAt}
        />
      )}
    </div>
  );
}
