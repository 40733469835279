
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Fingerprint from "@material-ui/icons/Fingerprint";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Typography from '@material-ui/core/Typography';
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import { Link } from "react-router-dom";
import CreateIcon from '@material-ui/icons/Create';
import CollectionsIcon from '@material-ui/icons/Collections';
const useStyles = makeStyles(styles);
function Account(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 400);

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={9} >
          <Card className={classes[cardAnimaton]}>
            <CardHeader color="info" className={classes.cardHeader}>
              <h3>Are you a Collector or a Creator?</h3>
            </CardHeader>
            <CardBody>
              <Typography gutterBottom variant="body1" color="textSecondary" component="p">
                <p className={classes.divider}>A Niftroner can either be a Collector or a Creator.
                Who do you think best suites your intentions?</p>
              </Typography>
            </CardBody>
            <CardBody>
              <GridContainer justify="center">
                <GridItem xs={12} sm={6} md={6} >
                  <Card className={classes[cardAnimaton]} >
                    <Link onClick={(e) => {
                      e.preventDefault()
                      props.setType("1")
                      props.setStep('PRIVACY')
                    }} >
                      <CardHeader color="info"
                        className={classes.cardHeader + " btn"}
                        style={{ hover: 50 }}  >
                        <h3>
                          Collector<CollectionsIcon style={{ fontSize: 40 }} /></h3>
                      </CardHeader>
                    </Link>
                    <CardBody row color="info">
                      A Collector is the heart of the system. They use the system to find the unique items
                      that they can purchase and attain ownership of.
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} >
                  <Card className={classes[cardAnimaton]}>
                    <Link onClick={(e) => {
                      e.preventDefault()
                      props.setType("0")
                      props.setStep('PRIVACY')
                    }} >
                      <CardHeader color="info"
                        className={classes.cardHeader + " btn"}
                        style={{ hover: 50 }}  >
                        <h3>
                          Creator<CreateIcon style={{ fontSize: 40 }} /></h3>
                      </CardHeader>
                    </Link>
                    <CardBody row>
                      A Creator is the other heart of the system. They create unique items
                      of immeasurable value which the Collectors can find and purchase.
                    </CardBody>
                  </Card>
                </GridItem>

              </GridContainer>
            </CardBody>
            <Typography gutterBottom variant="body2" color="textSecondary" align={"center"}>
              Already a niftroner?<Button simple color="info" onClick={(e) => {
                e.preventDefault()
                props.setStep('LOGIN')
              }}>Login</Button>
            </Typography>

          </Card>
        </GridItem>
      </GridContainer>
    </div>

  );
}

export default Account