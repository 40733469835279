import React from 'react'
// import './payment_modal.css'
import Button from 'components/CustomButtons/Button.js'

const PaymentModal = ({
  orderId,
  name,
  amount,
  alias,
  email,
  phone,
  address,
  city,
  country,
  publicKey
}) => {
  // const [amount,setAmount]= React.useState()
  // Put the payment variables here
  const payment = {
    sandbox: true, // if the account is sandbox or real
    merchant_id: '1214293', // Replace your Merchant ID
    return_url: 'http://localhost:3000/payment/success',
    cancel_url: window.location.href,
    notify_url:
      'https://api.niftron.com/payments',
    order_id: orderId,
    items: name,
    amount: amount,
    currency: 'USD',
    first_name: alias,
    last_name: publicKey,
    email: email,
    phone: phone,
    address: address,
    city: city,
    country: country,
    // delivery_address: 'No. 46, Galle road, Kalutara South', // optional field
    // delivery_city: city, // optional field
    // delivery_country: country, // optional field
    custom_1: '', // optional field
    custom_2: '' // optional field
  }

  // Called when user completed the payment. It can be a successful payment or failure
  window.payhere.onCompleted = async function onCompleted (orderId) {
    console.log('Payment completed. OrderID:' + orderId)
    // (async=()=>{

    // await
    // })()
    //Note: validate the payment and show success or failure page to the customer
  }

  // Called when user closes the payment without completing
  window.payhere.onDismissed = async function onDismissed () {
    //Note: Prompt user to pay again or show an error page
    console.log('Payment dismissed')
  }

  // Called when error happens when initializing payment such as invalid parameters
  window.payhere.onError = async function onError (error) {
    // Note: show an error page
    console.log('Error:' + error)
  }

  function pay () {
    window.payhere.startPayment(payment)
  }

  return (
    <Button
      color='info'
      size='lg'
      disabled={amount == "0"}
      // type='submit'
      onClick={pay}
    >
      Pay with Payhere
    </Button>
  )
}

export default PaymentModal
