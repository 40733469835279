
import React, { useState, useEffect } from "react";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import Card from "components/Card/Card.js";

import CardBody from "components/Card/CardBody.js";


import IssueAccess from "views/Token/Transfer/IssueAccess"
import { getUserSession, decryptSecret, encyrptSecret } from "services/UserManagement"

import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import axios from "axios";
import styles from "assets/jss/material-kit-react/views/loginPage";
import sha256 from "sha256";
import Icon from "@material-ui/core/Icon";
import Dropzone from 'react-dropzone';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomInput from "components/CustomInput/CustomInput";
import { useSnackbar } from 'notistack';
import { Link } from "react-router-dom";
import Button from "components/CustomButtons/Button";

const useStyles2 = makeStyles(styles);

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        // width: 500,
    },
    paperName: {
        padding: theme.spacing(1),
        // textAlign: 'center',
        // color: theme.palette.text.secondary,
    },
    paperValue: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export default function Record(props) {
    const classes2 = useStyles2();
    const { enqueueSnackbar } = useSnackbar();

    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [user, setUser] = useState(getUserSession())

    const [decrypted, setDecrypted] = useState(false);

    const [ipfsData, setIpfsData] = useState(null);
    const [ipfsDataArray, setIpfsDataArray] = useState([]);


    //d-art error
    const [fileError, setFileError] = useState(false)
    //Confirm 
    //d-art
    const [secret, setSecret] = useState("");
    //d-art error
    const [secretError, setSecretKeyError] = useState(false);

    const [loading, setLoading] = useState(false);
    const [loadingMessage, setloadingMessage] = useState("please remain patient");

    const [hoverPassword, setHoverPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [linkStyle, setLinkStyle] = useState({ cursor: 'pointer' });

    const [open, setOpen] = React.useState(false);


    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };



    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };
    const DecryptStuff = (e) => {
        e.preventDefault()
        let secretKey = "";
        if (user.authType !== "2") {
            secretKey = decryptSecret(user.encryptedSecret, sha256(secret))
        }
        if (user.authType === "2") {
            secretKey = secret.toUpperCase()
        }

        if (!secretKey || secretKey == "" || secretKey == null || secretKey == undefined) {
            enqueueSnackbar("Invalid Credential", { variant: "warning" });
        } else {

            let canShow = true
            let dataArray = ipfsDataArray
            dataArray.forEach((item, index) => {
                if (item.data.dataVisibility == "PRIVATE") {
                    let token = item
                    const res = decryptSecret(item.data.dataValue, secretKey)
                    if (res == null) {
                        enqueueSnackbar("Invalid Credential", { variant: "warning" });
                        canShow = false
                        return
                    }
                    token.data.dataValue = res

                    // console.log(token)
                    dataArray[index] = token
                }
            })

            if (canShow) {
                setIpfsDataArray(dataArray)
                setDecrypted(true)
            }

            handleClose()


        }
    };
    const makePresentable = (text) => {
        const response = text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
        return response.length > 20 ? response.substring(0, 20) + " ..." : response
    };

    useEffect(() => {
        async function fetchIpfs() {
            try {
                const res = await axios.get(
                    `https://niftron.infura-ipfs.io/ipfs/${props.item.ipfsHash}`
                );
                if (res) {
                    // console.log(res.data)
                    if (Object.keys(res.data).length === 0) {
                        setIpfsData("NA");
                    } else {
                        setIpfsData(JSON.stringify(res.data));
                        var array = [];

                        for (var i in res.data)
                            array.push({ key: i, data: res.data[i] });

                        setIpfsDataArray(array)
                    }
                    // setIpfsData(res.data)
                }
            } catch (er) {
                console.log(er);
            }
        }

        fetchIpfs()
    }, []);
    return (
        <Card>
            <Dialog
                maxWidth="sm"
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                scroll={"body"}
            >
                <DialogTitle id="responsive-dialog-title">{"Please Confirm Action"}</DialogTitle>
                <DialogContent dividers>
                    <form style={loading ? {
                        filter: "blur(1px)",
                        "-webkit-filter": "blur(1px)"
                    } : null} onSubmit={DecryptStuff}>
                        <CustomInput
                            error={secretError}
                            labelText={user.authType === "2" ? "Secret Key *" : "Password *"}
                            id="tokenName"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                type: !showPassword ? "password" : "text",
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {hoverPassword &&
                                            <Icon style={linkStyle} onMouseEnter={() => { setHoverPassword(true) }} onMouseLeave={() => { setHoverPassword(false) }}
                                                className={classes.inputIconsColor} onClick={() => {
                                                    setShowPassword(!showPassword)
                                                }}>
                                                {showPassword ? "visibilityoff" : "visibility"}</Icon>
                                        }
                                        {!hoverPassword &&
                                            <Icon style={linkStyle} className={classes.inputIconsColor}
                                                onMouseEnter={() => { setHoverPassword(true) }} onMouseLeave={() => { setHoverPassword(false) }}>
                                                lock</Icon>
                                        }
                                    </InputAdornment>
                                ), required: true,
                                onChange: function (e) {
                                    setSecret(e.target.value)
                                    setSecretKeyError(e.target.value === "" ? true : false)
                                }, value: secret
                            }}
                        />
                        {user.authType === "2" && <>
                            <Grid
                                container
                                spacing={0}
                                direction="column"
                                alignItems="center"
                                justify="center"
                            >

                                <Grid item xs={3}>
                                    or                  </Grid>

                            </Grid>

                            <Dropzone onDrop={acceptedFiles => {
                                const reader = new FileReader()
                                reader.addEventListener("load", async () => {
                                    // setFile(reader.result)
                                    setSecret(JSON.parse(atob(reader.result)).secretKey)
                                });
                                reader.readAsText(acceptedFiles[0])
                            }}
                                multiple={false} accept={[".niftron"]}>
                                {({ getRootProps, getInputProps }) => (
                                    <Link >
                                        <section className={"container"}>
                                            <div {...getRootProps({ className: fileError ? 'dropzone2Error' : 'dropzone2' })}>
                                                <input {...getInputProps()} />
                                                <p>Drop or select your niftron credential file</p>

                                            </div>
                                        </section>
                                    </Link>
                                )}
                            </Dropzone></>
                        }
                        <Grid container className={classes.root} spacing={2}>
                            <Grid item xs={12}>
                                <Grid container justify="center" spacing={2}>
                                    <Grid item>
                                        <Button color={"info"} onClick={DecryptStuff}
                                            type={"submit"} disabled={loading}>Confirm</Button>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>

                    </form>
                </DialogContent>
            </Dialog>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12} >
                    <div className={classes.root}>
                        <AppBar position="static" color="default">
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                // variant="scrollable"
                                scrollButtons="auto"

                                aria-label="full width tabs example"
                            >
                                <Tab label="Details" />
                                <Tab label="Issue Access Token" />
                            </Tabs>
                        </AppBar>
                        <SwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={value}
                            onChangeIndex={handleChangeIndex} >
                            <TabPanel value={value} index={0} dir={theme.direction}>
                                <div className={classes.container}>
                                    <Card>
                                        <CardBody style={{ height: "auto" }}>
                                            <div className={classes.root}>
                                                <Grid container spacing={1}>
                                                    <Grid container item xs={12} spacing={3}>
                                                        <Grid item xs={4}>
                                                            <Paper className={classes.paperName}> Name: </Paper>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <Paper className={classes.paperValue}>{props.item.tokenName}</Paper>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container item xs={12} spacing={3}>
                                                        <Grid item xs={4}>
                                                            <Paper className={classes.paperName}> Niftron ID: </Paper>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <Paper className={classes.paperValue}>{props.item.assetCode}</Paper>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container item xs={12} spacing={3}>
                                                        <Grid item xs={4}>
                                                            <Paper className={classes.paperName}>Quantity:</Paper>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <Paper className={classes.paperValue}>{props.item.assetCount}</Paper>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container item xs={12} spacing={3}>
                                                        <Grid item xs={4}>
                                                            <Paper className={classes.paperName}>Type: </Paper>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <Paper className={classes.paperValue}>{props.item.tokenType}</Paper>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container item xs={12} spacing={3}>
                                                        <Grid item xs={4}>
                                                            <Paper className={classes.paperName}>  Category:</Paper>
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <Paper className={classes.paperValue}>{props.item.category}</Paper>
                                                        </Grid>
                                                    </Grid>

                                                    {ipfsData && ipfsDataArray.length > 0 && ipfsDataArray.map((item, i) => (
                                                        <>
                                                            <Grid container item xs={12} spacing={3}>
                                                                <Grid item xs={4}>
                                                                    <Paper className={classes.paperName}>{makePresentable(item.key)}</Paper>
                                                                </Grid>
                                                                {item.data.dataType === "Image" &&
                                                                    <Grid item xs={8}>
                                                                        {item.data.dataVisibility == "PRIVATE" && decrypted ?
                                                                            <Paper className={classes.paperValue}> <img src={item.data.dataValue} style={{  width: "100%" }}
                                                                                className={classes2.imgRounded + " " + classes2.imgFluid} alt={item.key} /></Paper> :
                                                                            <Paper className={classes.paperValue}>CONFIDENTIAL!</Paper>}

                                                                        {item.data.dataVisibility != "PRIVATE" &&
                                                                            <Paper className={classes.paperValue}> <img src={item.data.dataValue} style={{  width: "100%" }}
                                                                                className={classes2.imgRounded + " " + classes2.imgFluid} alt={item.key} /></Paper>}
                                                                    </Grid>
                                                                }
                                                                {item.data.dataType !== "Image" &&
                                                                    <Grid item xs={8}>
                                                                        {item.data.dataVisibility == "PRIVATE" && decrypted ?
                                                                            <Paper className={classes.paperValue}>{item.data.dataValue}</Paper> :
                                                                            <Paper className={classes.paperValue}>CONFIDENTIAL!</Paper>}
                                                                        {item.data.dataVisibility != "PRIVATE" &&
                                                                            <Paper className={classes.paperValue}>{item.data.dataValue}</Paper>}
                                                                    </Grid>
                                                                }
                                                            </Grid>
                                                        </>
                                                    ))}
                                                </Grid>
                                                {!decrypted && <Grid container className={classes.root} spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Grid container justify="center" spacing={2}>
                                                            <Grid item>
                                                                <Button disabled={loading} color={"info"} onClick={handleOpen}>Decrypt Private Data</Button>

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>}

                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            </TabPanel>
                            <TabPanel value={value} index={1} dir={theme.direction}>
                                <IssueAccess item={props.item} ipfsData={ipfsData} /></TabPanel>
                        </SwipeableViews>
                    </div>

                </GridItem>
            </GridContainer>

        </Card>
    );
}
