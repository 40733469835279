/* eslint-disable */

import React, { useEffect, useState } from "react";
// react plugin for creating charts
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinIcon,
  LinkedinShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
  EmailIcon,
  EmailShareButton,
} from "react-share";
import Art from "views/Token/Components/Art";
import Ticket from "views/Token/Components/Ticket";
import Certificate from "views/Token/Components/Certificate";
import Badge from "views/Token/Components/Badge";
import Record from "views/Token/Components/Record";
import TokenMaticNiftron from "views/Token/Components/TokenMaticNiftron";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardToken from "components/Card/CardToken.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

export default function TokenMorpherMatic(props) {

  console.log(props)
  // let issuerAlias = "";
  // const issuerNames = props.item.issuerAlias;
  // const issuerNameBreakdown = issuerNames.split(" ");
  // issuerNameBreakdown.map((issuer) => {
  //   issuerAlias += issuer.charAt(0).toUpperCase() + issuer.substring(1) + " ";
  // });

  // // const issuerAlias = props.item.issuerAlias.charAt(0).toUpperCase() + props.item.issuerAlias.substring(1);
  // const userAlias =
  //   props.alias.charAt(0).toUpperCase() + props.alias.substring(1);
  // const tokenName =
  //   props.item.tokenName.charAt(0).toUpperCase() +
  //   props.item.tokenName.substring(1);

  // const [tokenDetails, setTokenDetails] = useState(
  //   tokenName + " was issued by " + issuerAlias + " to " + userAlias
  // );

  return (
    <div>
      <TokenMaticNiftron  
       isMatic={true}
          item={props.item}
          tokenID={props.tokenID}
          contractID={props.contractID}
          publicKey={props.publicKey}
          alias={props.alias} />

      <Card>
        <CardHeader>
          Share Your NFT         
        </CardHeader>
        <CardBody>
          <FacebookShareButton
            url={`http://explorer.niftron.com/nft?chainID=137&contractID=${props.contractID}&tokenID=${props.tokenID}`}
            quote={`${props.item.description}`}
            hashtag="Niftron"
          >
            <FacebookIcon size={32} round={false} borderRadius={6} />
          </FacebookShareButton>

          <LinkedinShareButton
            url={`http://explorer.niftron.com/nft?chainID=137&contractID=${props.contractID}&tokenID=${props.tokenID}`}

            source="Niftron"
            summary={`${props.item.description}`}
            title={`${props.item.name}`}
          >
            <LinkedinIcon size={32} round={false} borderRadius={6} />
          </LinkedinShareButton>

          <FacebookMessengerShareButton
            url={`http://explorer.niftron.com/nft?chainID=137&contractID=${props.contractID}&tokenID=${props.tokenID}`}
            appId="862394367580761"
            redirectUri={`https://profile.niftron.com/nft/${props.tokenID}`}
            to=""
          >
            <FacebookMessengerIcon size={32} round={false} borderRadius={6} />
          </FacebookMessengerShareButton>

          <TwitterShareButton
            url={`http://explorer.niftron.com/nft?chainID=137&contractID=${props.contractID}&tokenID=${props.tokenID}`}
            title={`${props.item.name}`}
            hashtags={["Blockchain", "Niftron"]}
          >
            <TwitterIcon size={32} round={false} borderRadius={6} />
          </TwitterShareButton>

          <WhatsappShareButton
            url={`http://explorer.niftron.com/nft?chainID=137&contractID=${props.contractID}&tokenID=${props.tokenID}`}
            title={`${props.item.name}`}
          >
            <WhatsappIcon size={32} round={false} borderRadius={6} />
          </WhatsappShareButton>

          <TelegramShareButton
            url={`http://explorer.niftron.com/nft?chainID=137&contractID=${props.contractID}&tokenID=${props.tokenID}`}
            title={`${props.item.name}`}
          >
            <TelegramIcon size={32} round={false} borderRadius={6} />
          </TelegramShareButton>

        </CardBody>
      </Card>
    </div>
  );
}
