/* eslint-disable */
import React, { useState } from 'react'
import {
  Divider,
  Box,
  Typography,
  CardContent,
  makeStyles
} from '@material-ui/core'
// import Page from 'components/Page'
import PaymentModal from './PaymentModal.js'
import Niftron from 'baas/config.js'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import axios from 'axios'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardFooter from 'components/Card/CardFooter.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
import SnackbarContent from 'components/Snackbar/SnackbarContent.js'
import styles from 'assets/jss/material-kit-react/views/loginPage.js'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dropzone from 'react-dropzone'
import { Link } from 'react-router-dom'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles(styles)

const ActivateView = () => {
  const [cardAnimaton, setCardAnimation] = React.useState('cardHidden')
  setTimeout(function () {
    setCardAnimation('')
  }, 400)
  const classes = useStyles()
  const [authUser, setAuthUser] = React.useState(null)
  // const [accountActivationXLM, setAccountActivationXLM] = React.useState(3.1)
  // const [XLMToUSDRate, setXLMToUSDRate] = React.useState(0.6)
  // const [USDToLKRRate, setUSDToLKRRate] = React.useState(200)
  const [billingAmount, setbillingAmount] = React.useState(0)

  React.useEffect(() => {
    const fetchUser = async () => {
      Niftron.user.onAuthStateChanged(
        authUser => {
          setAuthUser(authUser)
          console.log(authUser)
        },
        err => {
          if (err) {
            console.log(err)
          }
        }
      )
    }
    // const fetchRates = async () => {
    //   const xlmUSD = await axios.get(
    //     'https://min-api.cryptocompare.com/data/price?fsym=XLM&tsyms=USD'
    //   )
    //   setXLMToUSDRate(xlmUSD.data.USD)

    //   // lkrUSD.data.USD_LKR
    // }

    fetchUser()
    // fetchRates()
  }, [])

  // React.useEffect(() => {
  //   setbillingAmount(accountActivationXLM * XLMToUSDRate)
  // }, [XLMToUSDRate, USDToLKRRate])
  return (
    <div className={classes.container}>
      <GridContainer justify='center'>
        <GridItem xs={12} sm={12} md={10} lg={8}>
          <Card className={classes[cardAnimaton]}>
            <form
              className={classes.form}
              // onSubmit={e => {
              //   e.preventDefault()
              // }}
            >
              {' '}
              <CardHeader color='info' className={classes.cardHeader}>
                <h3>Recharge</h3>
              </CardHeader>
              <CardBody>
                <GridContainer justify='center'>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      color='info'
                      // error={assetCountError}
                      labelText='amount'
                      id='fromBlockchain'
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: 'number',
                        required: true,
                        onChange: function (e) {
                          setbillingAmount(e.target.value)
                        },
                        value: billingAmount,
                        // disabled: props.item.tokenType === 'NFT' ? false : true
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter className={classes.cardFooter}>
                {authUser && (
                  <PaymentModal
                    orderId={authUser.publicKey + '-ACTIVATION'}
                    description={'Account Activation'}
                    amount={billingAmount}
                    alias={authUser.alias}
                    email={authUser.email}
                    phone={'0765785123'}
                    address={'43/6, Princess Gate'}
                    city={'Colombo'}
                    country={'Sri Lanka'}
                    publicKey={authUser.publicKey}
                  />
                )}
              </CardFooter>
            </form>

            <Divider />
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
}

export default ActivateView
