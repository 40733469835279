/* eslint-disable */
import React, { useEffect, useState } from "react";
// @material-ui/core
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
// import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Keypair } from "stellar-sdk";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import TokenMorpher from "views/VaultList/Components/TokenMorpher"
import DummyToken from "views/VaultList/Components/DummyToken"
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { getUserSession, decryptSecret } from "services/UserManagement"
import { BuildProofXDR, signXDR } from "services/XDRBuilder"
import sha256 from "sha256";

// import Paginations from "components/Pagination/Pagination.js";
import Pagination from '@material-ui/lab/Pagination';
import Grid from '@material-ui/core/Grid';
import { withRouter, Link } from "react-router-dom";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { format } from 'timeago.js';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from "components/CustomButtons/Button";
import CardToken from "components/Card/CardToken.js";
import CardFooter from "components/Card/CardFooter.js";
import DateRange from "@material-ui/icons/DateRange";
import { CardMedia } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import axios from "axios";

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dropzone from 'react-dropzone';
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import CustomInput from "components/CustomInput/CustomInput.js";
import { AcceptApproval, RejectApproval, ListApprovalsBySigner } from "services/ApprovalManagement";

import CircularProgress from '@material-ui/core/CircularProgress';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'createdAt', numeric: false, disablePadding: false, label: 'Received At' },
  { id: 'tokenName', numeric: false, disablePadding: false, label: 'Token Name' },
  { id: 'assetCode', numeric: false, disablePadding: false, label: 'Niftron Id' },
  { id: 'sender', numeric: false, disablePadding: false, label: 'Sender' },
  { id: 'receiver', numeric: false, disablePadding: false, label: 'Receiver' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'actions', numeric: false, disablePadding: false, label: 'Actions' },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
            Approvals
          </Typography>
        )}

      {numSelected > 0 ? (
        <>
          <Button color={"success"} disabled={props.loading} onClick={(e) => {
            e.preventDefault()
            props.handleOpen("ACCEPT")
          }} >Approve All</Button>
          <Button color={"danger"} disabled={props.loading} onClick={(e) => {
            e.preventDefault()
            props.handleOpen("REJECT")
          }} >Reject All</Button>
        </>

        // <Tooltip title="Delete">
        //   <IconButton aria-label="delete">
        //     <DeleteIcon />
        //   </IconButton>
        // </Tooltip>
      ) : (
          // <Tooltip title="Filter list">
          //   <IconButton aria-label="filter list">
          //     <FilterListIcon />
          //   </IconButton>
          // </Tooltip>
          null
        )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function Row(props) {
  const { row } = props;
  const [user, setUser] = useState(getUserSession())
  const [open, setOpen] = React.useState(false);
  const [ipfsData, setIpfsData] = useState(null)
  const [ipfsDataArray, setIpfsDataArray] = useState([]);

  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [status, setStatus] = React.useState(row.status == "ACCEPTED" ? "APPROVED" : row.status);
  // const [inActive, setInActive] = React.useState(row.status);

  //Confirm 
  const [secret, setSecret] = useState("");

  //Confirm error
  const [secretError, SecretKeyError] = useState(false);
  const [fileError, setFileError] = useState(false)

  //hover and show
  const [hoverPassword, setHoverPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [linkStyle, setLinkStyle] = useState({ cursor: 'pointer' });
  const [actionType, setActionType] = useState("");

  const { enqueueSnackbar } = useSnackbar();

  const classes = useRowStyles();

  const makePresentable = (text) => {
    const response = text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
    return response.length > 20 ? response.substring(0, 20) + " ..." : response
  };
  const makePresentablePk = (text) => {
    const response = text
    return response.length > 20 ? response.substring(0, 20) + " ..." : response
  };
  useEffect(() => {
    async function fetchIpfs() {
      try {
        const res = await axios.get(`https://niftron.infura-ipfs.io/ipfs/${row.data.ipfsHash}`);
        if (res) {
          //console.log(res.data)
          if (Object.keys(res.data).length === 0) {
            setIpfsData("NA")
          } else {
            setIpfsData(JSON.stringify(res.data));
            var array = [];

            for (var i in res.data)
              array.push({ key: i, value: res.data[i] });

            setIpfsDataArray(array)
          }
        }
      } catch (er) {
        //console.log(er)
      }
    }
    fetchIpfs()
  }, []);
  useEffect(() => {
    function setStates() {
      try {
        setStatus(row.status == "ACCEPTED" ? "APPROVED" : row.status)
      } catch (er) {
        //console.log(er)
      }
    }
    setStates()
  }, [row]);
  const handleClose = () => {
    setOpenDialog(false);
  };
  const handleOpen = (action) => {
    setOpenDialog(true);
    setActionType(action)
  };
  const performAction = async () => {
    props.setLoading(true)
    setOpenDialog(false);

    let secretKey = "";
    if (user.authType !== "2") {
      secretKey = decryptSecret(user.encryptedSecret, sha256(secret))
    }
    if (user.authType === "2") {
      secretKey = secret.toUpperCase()
    }
    if (!secretKey || secretKey == "" || secretKey == null || secretKey == undefined) {
      enqueueSnackbar("Invalid Credential", { variant: "warning" });
    } else {

      if (Keypair.fromSecret(secretKey).publicKey() !== user.publicKey) {
        enqueueSnackbar("Invalid Credential", { variant: "warning" });

      } else {
        if (actionType == "ACCEPT") {
          await accept(secretKey)
        }
        if (actionType == "REJECT") {
          await reject(secretKey)
        }

      }

    }
    props.setLoading(false)
  };

  const accept = async (secretKey) => {
    window.addEventListener('beforeunload', function (e) {
      e.preventDefault();
      e.returnValue = '';
    });
    setStatus("APPROVING")
    let { xdrs } = await BuildProofXDR(user.publicKey, row.data.txnHash, "ACCEPT")
    let xdr;
    let proofXdr;

    if (xdrs != null && xdrs.length > 0) {
      await Promise.all(
        xdrs.map(async (item, index, array) => {
          xdrs[index].xdr = await signXDR(item.xdr, secretKey);
        })
      );
      proofXdr = xdrs[0]?.xdr
    }
    xdr = await signXDR(row.data.xdr, secretKey);
    //console.log(xdr, proofXdr)


    const serverRes = await AcceptApproval(row._id, {
      proofXdr,
      xdr
    })
    switch (serverRes) {
      case 200: enqueueSnackbar("Token Approved", { variant: "success" });
        setStatus("APPROVED");
        props.fetchData(); break;
      case 201: enqueueSnackbar("Account Not Found", { variant: "warning" }); setStatus("PENDING"); break;
      case 202: enqueueSnackbar("Insufficient Fund in Account", { variant: "warning" }); setStatus("PENDING"); break;
      case 203: enqueueSnackbar("Approval is Pending", { variant: "warning" }); setStatus("PENDING"); break;
      case 204: enqueueSnackbar("Transfer Not Found", { variant: "warning" }); setStatus("PENDING"); break;
      case 206: enqueueSnackbar("Approve Transfer Failed", { variant: "warning" }); setStatus("PENDING"); break;
      case 400: enqueueSnackbar("Approve Transfer Failed", { variant: "error" }); setStatus("PENDING"); break;
      case null: enqueueSnackbar("Approve Transfer Failed", { variant: "error" }); setStatus("PENDING"); break;
      default: enqueueSnackbar("Approve Transfer Failed", { variant: "error" }); setStatus("PENDING");
    }
    window.removeEventListener('beforeunload', function (e) {
      e.preventDefault();
      e.returnValue = '';
    });
  }

  const reject = async (secretKey) => {
    window.addEventListener('beforeunload', function (e) {
      e.preventDefault();
      e.returnValue = '';
    });
    setStatus("REJECTING")

    let { xdrs } = await BuildProofXDR(user.publicKey, row.data.txnHash, "REJECT")
    let xdr;

    let proofXdr;
    if (xdrs != null && xdrs.length > 0) {
      await Promise.all(
        xdrs.map(async (item, index, array) => {
          xdrs[index].xdr = await signXDR(item.xdr, secretKey);
        })
      );
      proofXdr = xdrs[0]?.xdr
    }
    xdr = await signXDR(row.data.rejectXdr, secretKey);

    //console.log(xdr, proofXdr)

    const serverRes = await RejectApproval(row._id, {
      proofXdr,
      xdr
    })
    switch (serverRes) {
      case 200:
        enqueueSnackbar("Token Rejected", { variant: "success" });
        setStatus("REJECTED"); props.fetchData();
        break;
      case 201: enqueueSnackbar("Account Not Found", { variant: "warning" }); setStatus("PENDING"); break;
      case 202: enqueueSnackbar("Insufficient Fund in Account", { variant: "warning" }); setStatus("PENDING"); break;
      case 203: enqueueSnackbar("Approval is Pending", { variant: "warning" }); setStatus("PENDING"); break;
      case 204: enqueueSnackbar("Transfer Not Found", { variant: "warning" }); setStatus("PENDING"); break;
      case 206: enqueueSnackbar("Reject Transfer Failed", { variant: "warning" }); setStatus("PENDING"); break;
      case 400: enqueueSnackbar("Reject Transfer Failed", { variant: "error" }); setStatus("PENDING"); break;
      case null: enqueueSnackbar("Reject Transfer Failed", { variant: "error" }); setStatus("PENDING"); break;
      default: enqueueSnackbar("Reject Transfer Failed", { variant: "error" }); setStatus("PENDING");
    }

    window.removeEventListener('beforeunload', function (e) {
      e.preventDefault();
      e.returnValue = '';
    });
  }

  return (
    <React.Fragment>
      <Dialog
        maxWidth="sm"
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        scroll={"body"}
      >
        <DialogTitle id="responsive-dialog-title">{"Please Confirm Action"}</DialogTitle>
        <DialogContent dividers>
          <form style={loading ? {
            filter: "blur(1px)",
            "-webkit-filter": "blur(1px)"
          } : null} onSubmit={(event) => {
            event.preventDefault()
            performAction()
          }}>
            <CustomInput
              error={secretError}
              labelText={user.authType === "2" ? "Secret Key *" : "Password *"}
              id="tokenName"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: !showPassword ? "password" : "text",
                endAdornment: (
                  <InputAdornment position="end">
                    {hoverPassword &&
                      <Icon style={linkStyle} onMouseEnter={() => { setHoverPassword(true) }} onMouseLeave={() => { setHoverPassword(false) }}
                        className={classes.inputIconsColor} onClick={() => {
                          setShowPassword(!showPassword)

                        }}>
                        {showPassword ? "visibilityoff" : "visibility"}</Icon>
                    }
                    {!hoverPassword &&
                      <Icon style={linkStyle} className={classes.inputIconsColor}
                        onMouseEnter={() => { setHoverPassword(true) }} onMouseLeave={() => { setHoverPassword(false) }}>
                        lock</Icon>
                    }
                  </InputAdornment>
                ), required: true,
                onChange: function (e) {
                  setSecret(e.target.value)
                  SecretKeyError(e.target.value === "" ? true : false)
                }, value: secret
              }}
            />
            {user.authType === "2" && <>
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
              >
                <Grid item xs={3}>or</Grid>
              </Grid>
              <Dropzone onDrop={acceptedFiles => {
                const reader = new FileReader()
                reader.addEventListener("load", async () => {
                  // setFile(reader.result)
                  setSecret(JSON.parse(atob(reader.result)).secretKey)
                });
                reader.readAsText(acceptedFiles[0])
              }}
                multiple={false} accept={[".niftron"]}>
                {({ getRootProps, getInputProps }) => (
                  <Link >
                    <section className={"container"}>
                      <div {...getRootProps({ className: fileError ? 'dropzone2Error' : 'dropzone2' })}>
                        <input {...getInputProps()} />
                        <p>Drop or select your niftron credential file</p>
                      </div>
                    </section>
                  </Link>
                )}
              </Dropzone></>
            }
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12}>
                <Grid container justify="center" spacing={2}>
                  <Grid item>
                    <Button color={"info"} onClick={(event) => {
                      event.preventDefault()
                      performAction()
                    }}
                      type={"submit"} disabled={loading}>Confirm</Button>
                  </Grid>

                </Grid>
              </Grid>
            </Grid>

          </form>
        </DialogContent>
      </Dialog>
      <TableRow
        hover
        // onClick={() => setOpen(!open)}
        // onClick={(event) => props.handleClick(event, row.data.name)}
        role="checkbox"
        aria-checked={props.isItemSelected}
        tabIndex={-1}
        key={row._id}
        selected={props.isItemSelected}
      >
        <TableCell padding="checkbox">
          <Checkbox
            disabled={status == "PENDING" ? false : true}
            onChange={(event) => props.handleClick(event, row._id)}
            checked={props.isItemSelected}
            inputProps={{ 'aria-labelledby': props.labelId }}
          />
        </TableCell>
        <TableCell component="th" scope="row">
          {format(row.data.createdAt)}
        </TableCell>
        <TableCell component="th" scope="row">{row.data.tokenName}</TableCell>
        <TableCell component="th" scope="row">{row.data.assetCode}</TableCell>
        <TableCell component="th" scope="row">{row.data.sender.substring(0, 10) + "..."}</TableCell>
        <TableCell component="th" scope="row">{row.data.receiver.substring(0, 10) + "..."}</TableCell>
        <TableCell component="th" scope="row">{status}</TableCell>
        <TableCell>
          <Button size="xs" color={"success"} disabled={status != "PENDING" || loading} onClick={(e) => {
            e.preventDefault()
            handleOpen("ACCEPT")
          }} >Approve</Button>
          <Button size="xs" color={"danger"} disabled={status != "PENDING" || loading} onClick={(e) => {
            e.preventDefault()
            handleOpen("REJECT")
          }} >Reject</Button>
          <Button size="xs" color={"info"} disabled={loading} onClick={() => setOpen(!open)}>{open ? "Hide Details" : "Show Details"}</Button>
          {/* <IconButton aria-label="expand row" size="small" >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton> */}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6} lg={6} >
                  <Card style={{
                    maxHeight: 500,
                    overflow: "auto"
                  }} >
                    <CardMedia style={{ height: 500, maxHeight: 500, "background-size": "contain" }} image={row.data.previewUrl} />
                    {/* <img style={{ height: "100%", width: "100%" }}
                      onContextMenu={e => {
                        e.preventDefault()
                      }}
                      src={row.data.previewUrl} /> */}
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={6} >
                  <div className={classes.root}>
                    <Card>
                      <CardBody >
                        <Grid container spacing={1}>
                          <Grid container item xs={12} sm={12} md={6} lg={6} spacing={3}>
                            <ListItem >
                              <ListItemText primary={"Name"} />
                            </ListItem>
                          </Grid>
                          <Grid container item xs={12} sm={12} md={6} lg={6} spacing={3}>
                            <ListItem >
                              <ListItemText secondary={row.data.tokenName} />
                            </ListItem>
                          </Grid>
                          <Divider variant="middle" />
                          <Grid container item item xs={12} sm={12} md={6} lg={6} spacing={3}>
                            <ListItem >
                              <ListItemText primary="Quantity" />
                            </ListItem>
                          </Grid>
                          <Grid container item xs={12} sm={12} md={6} lg={6} spacing={3}>
                            <ListItem >
                              <ListItemText secondary={row.data.assetCount} />
                            </ListItem>
                          </Grid>
                          <Divider variant="middle" />
                          <Grid container item item xs={12} sm={12} md={6} lg={6} spacing={3}>
                            <ListItem >
                              <ListItemText primary="Accredited By" />
                            </ListItem>
                          </Grid>
                          <Grid container item xs={12} sm={12} md={6} lg={6} spacing={3}>
                            <ListItem >
                              <ListItemText secondary={"Verify"} />
                            </ListItem>
                          </Grid>
                          <Divider variant="middle" />
                          <Grid container item xs={12} sm={12} md={6} lg={6} spacing={3}>
                            <ListItem >
                              <ListItemText primary="Niftron ID" />
                            </ListItem>
                          </Grid>
                          <Grid container item xs={12} sm={12} md={6} lg={6} spacing={3}>
                            <ListItem >
                              <ListItemText secondary={row.data.assetCode} />
                            </ListItem>
                          </Grid>
                          <Divider variant="middle" />
                          {ipfsData && ipfsDataArray.length > 0 && ipfsDataArray.map((item, i) => (
                            <><Grid container item xs={12} sm={12} md={6} lg={6} spacing={3}>
                              <ListItem >
                                <ListItemText primary={makePresentable(item.key)} />
                              </ListItem>
                            </Grid>
                              <Grid container item xs={12} sm={12} md={6} lg={6} spacing={3}>
                                <ListItem >
                                  <ListItemText secondary={item.value} />
                                </ListItem>
                              </Grid>
                              <Divider variant="middle" />
                            </>
                          ))
                          }
                          <Grid container item xs={12} sm={12} md={12} lg={12} spacing={3}>
                            <ListItem >
                              <ListItemText primary={"Approval Status"} />
                            </ListItem>
                          </Grid>
                          {row.data.signers && row.data.signers.length > 0 && row.data.signers.map((item, i) => (
                            <>
                              {/* <Grid container item xs={12} sm={12} md={6} lg={6} spacing={3}>
                                <ListItem >
                                  <ListItemText primary={makePresentablePk(item.publicKey)} />
                                </ListItem>
                              </Grid> */}
                              <Grid container item xs={6} sm={6} md={6} lg={6} spacing={3}>
                                <ListItem >
                                  <ListItemText secondary={makePresentablePk(item.publicKey)} />
                                </ListItem>
                              </Grid>
                              <Grid container item xs={6} sm={6} md={6} lg={6} spacing={3}>
                                <ListItem >
                                  <ListItemText secondary={item.status == "ACCEPTED" ? "APPROVED" : item.status} />
                                </ListItem>
                              </Grid>
                              <Divider variant="middle" />
                            </>
                          ))
                          }
                        </Grid>
                        {/* <GridContainer >
                          <GridItem xs={12} sm={12} md={12} lg={12} >
                            Name: {row.tokenName}
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12} lg={12} >
                            Niftron ID: {row.assetCode}
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12} lg={12} >
                            Quantity: {row.assetCount}
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12} lg={12} >
                            Data: {ipfsData}
                          </GridItem>
                        </GridContainer>
                     
                      */}
                      </CardBody>
                    </Card>
                  </div>
                </GridItem>
              </GridContainer>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable() {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('date');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [count, setCount] = useState(0)
  const [transfers, setTransfers] = React.useState([])
  const [user, setUser] = useState(getUserSession())
  // const [tokens, setTokens] = useState([])
  const [loading, setLoading] = useState(false)

  const [openDialog, setOpenDialog] = React.useState(false);
  //Confirm 
  const [secret, setSecret] = useState("");

  //Confirm error
  const [secretError, SecretKeyError] = useState(false);
  const [fileError, setFileError] = useState(false)

  //hover and show
  const [hoverPassword, setHoverPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [linkStyle, setLinkStyle] = useState({ cursor: 'pointer' });
  const [actionType, setActionType] = useState("");


  const [pendingCount, setPendingCount] = useState(0);

  const { enqueueSnackbar } = useSnackbar();


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      let newSelecteds = []
      transfers.map((n) => {
        if (n.status == "PENDING") {
          newSelecteds.push(n._id)
        }
      });
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    fetchData(parseInt(event.target.value, 10))

  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

  const fetchData = async (limit) => {
    let rowLength = rowsPerPage
    if (limit) {
      rowLength = limit
    }
    setLoading(true)
    const key = user.publicKey
    const result = await ListApprovalsBySigner(key, rowLength, page + 1)
    if (result != null) {
      if (result.data && result.data.length > 0) {
        let pendingCount = 0;
        // console.log(result.data)
        setTransfers(result.data)
        result.data.map((n) => {
          if (n.status == "PENDING") {
            pendingCount++
          }
        });
        setPendingCount(pendingCount)
        setCount(result.count)
      }
    }
    setLoading(false)
  }

  const fetchDataStatus = async (limit) => {
    let rowLength = rowsPerPage
    if (limit) {
      rowLength = limit
    }
    // setLoading(true)
    const key = user.publicKey
    const result = await ListApprovalsBySigner(key, rowLength, page + 1)
    if (result != null) {
      if (result.data && result.data.length > 0) {
        let pendingCount = 0;
        // console.log(result.data)
        setTransfers(result.data)
        result.data.map((n) => {
          if (n.status == "PENDING") {
            pendingCount++
          }
        });
        setPendingCount(pendingCount)
        setCount(result.count)
      }
    }
    // setLoading(false)
  }
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const key = user.publicKey
      const result = await ListApprovalsBySigner(key, rowsPerPage, page + 1)
      if (result != null) {
        if (result.data && result.data.length > 0) {
          let pendingCount = 0;
          // //console.log(result.data)
          setTransfers(result.data)
          result.data.map((n) => {
            if (n.status == "PENDING") {
              pendingCount++
            }
          });
          setPendingCount(pendingCount)
          setCount(result.count)
        }
      }
      setLoading(false)
    }
    fetchData()
  }, [page]);

  const updateTransfers = (transferList) => {
    setTransfers(transferList)
  }

  const handleClose = () => {
    setOpenDialog(false);
  };
  const handleOpen = (action) => {
    setOpenDialog(true);
    setActionType(action)
  };
  const performAction = async () => {
    setOpenDialog(false);

    let secretKey = "";
    if (user.authType !== "2") {
      secretKey = decryptSecret(user.encryptedSecret, sha256(secret))
    }
    if (user.authType === "2") {
      secretKey = secret.toUpperCase()
    }

    if (!secretKey || secretKey == "" || secretKey == null || secretKey == undefined) {
      enqueueSnackbar("Invalid Credential", { variant: "warning" });
    } else {

      if (Keypair.fromSecret(secretKey).publicKey() !== user.publicKey) {
        enqueueSnackbar("Invalid Credential", { variant: "warning" });

      } else {
        if (actionType == "ACCEPT") {
          await accept(secretKey)
        }
        if (actionType == "REJECT") {
          await reject(secretKey)
        }

      }

    }
  };

  const accept = async (secretKey) => {
    setLoading(true)

    let transferList = transfers;

    for (let i = 0, len = transfers.length; i < len; i++) {
      if (isSelected(transfers[i]._id)) {
        // transferList[i].status = "APPROVING"
        setTransfers(transferList)

        let { xdrs } = await BuildProofXDR(user.publicKey, transfers[i].data.txnHash, "ACCEPT")
        let xdr;
        let proofXdr;

        if (xdrs != null && xdrs.length > 0) {
          await Promise.all(
            xdrs.map(async (item, index, array) => {
              xdrs[index].xdr = await signXDR(item.xdr, secretKey);
            })
          );
          proofXdr = xdrs[0]?.xdr
        }
        xdr = await signXDR(transfers[i].data.xdr, secretKey);
        //console.log(xdr, proofXdr)

        const serverRes = await AcceptApproval(transfers[i]._id, {
          proofXdr,
          xdr
        })
        switch (serverRes) {
          case 200:
            transferList[i].status = "APPROVED"
            updateTransfers(transferList)
            fetchDataStatus(rowsPerPage)
            // setStatus("ACCEPTED");
            break;
          case 201: enqueueSnackbar("Account Not Found", { variant: "warning" });
            transferList[i].status = "PENDING"
            updateTransfers(transferList)

            break;
          case 202: enqueueSnackbar("Insufficient Fund in Account", { variant: "warning" });
            transferList[i].status = "PENDING"
            updateTransfers(transferList)

            break;
          case 203: enqueueSnackbar("Approval is Pending", { variant: "warning" });
            transferList[i].status = "PENDING"
            updateTransfers(transferList)

            break;
          case 204: enqueueSnackbar("Transfer Not Found", { variant: "warning" });
            transferList[i].status = "PENDING"
            updateTransfers(transferList)

            break;
          case 206: enqueueSnackbar("Approve Transfer Failed", { variant: "warning" });
            transferList[i].status = "PENDING"
            updateTransfers(transferList)

            break;
          case 400: enqueueSnackbar("Approve Transfer Failed", { variant: "error" });
            transferList[i].status = "PENDING"
            updateTransfers(transferList)

            break;
          case null: enqueueSnackbar("Approve Transfer Failed", { variant: "error" });
            transferList[i].status = "PENDING"
            updateTransfers(transferList)

            break;
          default: enqueueSnackbar("Approve Transfer Failed", { variant: "error" });
            transferList[i].status = "PENDING"
            updateTransfers(transferList)

        }

      }

    }
    enqueueSnackbar("Batch Request Processed, View Status For Results", { variant: "success" });
    setSelected([])
    setLoading(false)

  }

  const reject = async (secretKey) => {
       setLoading(true)

      let transferList = transfers;
  
      for (let i = 0, len = transfers.length; i < len; i++) {
        if (isSelected(transfers[i]._id)) {
          setTransfers(transferList)
  
          let { xdrs } = await BuildProofXDR(user.publicKey, transfers[i].data.txnHash, "REJECT")
          let xdr;
          let proofXdr;
  
          if (xdrs != null && xdrs.length > 0) {
            await Promise.all(
              xdrs.map(async (item, index, array) => {
                xdrs[index].xdr = await signXDR(item.xdr, secretKey);
              })
            );
            proofXdr = xdrs[0]?.xdr
          }
          xdr = await signXDR(transfers[i].data.xdr, secretKey);
          //console.log(xdr, proofXdr)
  
          const serverRes = await RejectApproval(transfers[i]._id, {
            proofXdr,
            xdr
          })
          switch (serverRes) {
            case 200:
              transferList[i].status = "REJECTED"
              updateTransfers(transferList)
              fetchDataStatus(rowsPerPage)
              // setStatus("ACCEPTED");
              break;
            case 201: enqueueSnackbar("Account Not Found", { variant: "warning" });
              transferList[i].status = "PENDING"
              updateTransfers(transferList)
  
              break;
            case 202: enqueueSnackbar("Insufficient Fund in Account", { variant: "warning" });
              transferList[i].status = "PENDING"
              updateTransfers(transferList)
  
              break;
            case 203: enqueueSnackbar("Rejection is Pending", { variant: "warning" });
              transferList[i].status = "PENDING"
              updateTransfers(transferList)
  
              break;
            case 204: enqueueSnackbar("Transfer Not Found", { variant: "warning" });
              transferList[i].status = "PENDING"
              updateTransfers(transferList)
  
              break;
            case 206: enqueueSnackbar("Reject Transfer Failed", { variant: "warning" });
              transferList[i].status = "PENDING"
              updateTransfers(transferList)
  
              break;
            case 400: enqueueSnackbar("Reject Transfer Failed", { variant: "error" });
              transferList[i].status = "PENDING"
              updateTransfers(transferList)
  
              break;
            case null: enqueueSnackbar("Reject Transfer Failed", { variant: "error" });
              transferList[i].status = "PENDING"
              updateTransfers(transferList)
  
              break;
            default: enqueueSnackbar("Reject Transfer Failed", { variant: "error" });
              transferList[i].status = "PENDING"
              updateTransfers(transferList)
  
          }
  
        }
  
      }
      enqueueSnackbar("Batch Request Processed, View Status For Results", { variant: "success" });
      setSelected([])
      setLoading(false)
      
  }

  return (
    <div className={classes.root}>
      <Dialog
        maxWidth="sm"
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        scroll={"body"}
      >
        <DialogTitle id="responsive-dialog-title">{"Please Confirm Action"}</DialogTitle>
        <DialogContent dividers>
          <form style={loading ? {
            filter: "blur(1px)",
            "-webkit-filter": "blur(1px)"
          } : null} onSubmit={(event) => {
            event.preventDefault()
            performAction()
          }}>
            <CustomInput
              error={secretError}
              labelText={user.authType === "2" ? "Secret Key *" : "Password *"}
              id="tokenName"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: !showPassword ? "password" : "text",
                endAdornment: (
                  <InputAdornment position="end">
                    {hoverPassword &&
                      <Icon style={linkStyle} onMouseEnter={() => { setHoverPassword(true) }} onMouseLeave={() => { setHoverPassword(false) }}
                        className={classes.inputIconsColor} onClick={() => {
                          setShowPassword(!showPassword)

                        }}>
                        {showPassword ? "visibilityoff" : "visibility"}</Icon>
                    }
                    {!hoverPassword &&
                      <Icon style={linkStyle} className={classes.inputIconsColor}
                        onMouseEnter={() => { setHoverPassword(true) }} onMouseLeave={() => { setHoverPassword(false) }}>
                        lock</Icon>
                    }
                  </InputAdornment>
                ), required: true,
                onChange: function (e) {
                  setSecret(e.target.value)
                  SecretKeyError(e.target.value === "" ? true : false)
                }, value: secret
              }}
            />
            {user.authType === "2" && <>
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
              >
                <Grid item xs={3}>or</Grid>
              </Grid>
              <Dropzone onDrop={acceptedFiles => {
                const reader = new FileReader()
                reader.addEventListener("load", async () => {
                  // setFile(reader.result)
                  setSecret(JSON.parse(atob(reader.result)).secretKey)
                });
                reader.readAsText(acceptedFiles[0])
              }}
                multiple={false} accept={[".niftron"]}>
                {({ getRootProps, getInputProps }) => (
                  <Link >
                    <section className={"container"}>
                      <div {...getRootProps({ className: fileError ? 'dropzone2Error' : 'dropzone2' })}>
                        <input {...getInputProps()} />
                        <p>Drop or select your niftron credential file</p>
                      </div>
                    </section>
                  </Link>
                )}
              </Dropzone></>
            }
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12}>
                <Grid container justify="center" spacing={2}>
                  <Grid item>
                    <Button color={"info"} onClick={(event) => {
                      event.preventDefault()
                      performAction()
                    }}
                      type={"submit"} disabled={loading}>Confirm</Button>
                  </Grid>

                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} loading={loading}
          handleOpen={handleOpen} />
        {loading && <LinearProgress style={{ justifySelf: "center" }} />}
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={pendingCount}
            />
            <TableBody>
              {transfers && stableSort(transfers, getComparator(order, orderBy))
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <Row key={row._id} row={row} isItemSelected={isItemSelected}
                      handleClick={handleClick} isItemSelected={isItemSelected} labelId={labelId}
                      fetchData={fetchData} setLoading={setLoading} />
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {loading && <LinearProgress style={{ justifySelf: "center" }} />}

        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </div>
  );
}
